import React, { useState, useEffect } from 'react';

const tips = [
  "Try different settings when generating your transcript",
  "Each block has a grip for rearranging up or down",
  "Press SPACE to play/pause",
  "Click any word or block to seek to it",
  "Use ← → ↑ ↓ or WASD to navigate between words and blocks ",
  "Press R to reject the current block or selected words",
  "Press I to isolate specific words and reject others in block",
  "Press J or L to reject words to the left or right",
  "You can edit word-timing with the Text Editor tool (T)",
];

export const Tips = () => {
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      
      setTimeout(() => {
        setCurrentTipIndex(prev => (prev + 1) % tips.length);
        setIsVisible(true);
      }, 500);
      
    }, 8000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex justify-center items-center h-full">
      <div 
        className={`font-spooky text-lg text-default-600 transition-all duration-500 ease-in-out
          ${isVisible ? 'opacity-70 translate-y-0' : 'opacity-0 -translate-y-4'}`}
      >
        {tips[currentTipIndex]}
      </div>
    </div>
  );
}; 