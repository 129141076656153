import React from 'react';
// import useStore from '../../store';
import { useFileUpload } from '../../hooks';
import { FileUploadSection } from '../../components';

export const UploadFlow = () => {
  const {
    isUploading,
    uploadError,
    handleFileUpload
  } = useFileUpload();

  return (
    <div className="h-full overflow-hidden center-section col-span-12">
      {!isUploading ? (
        <div className="h-full overflow-hidden w-full flex flex-col text-center justify-center items-center p-8">
          <h2 className="text-2xl font-bold mb-4">Welcome</h2>
          <p className="text-default-600 mb-8">
            Upload your video here.
            <br />
            <br />
            Make sure to include all footage you'd like to edit in a single video file.
            <br />
            <br />
            Your decisions in Spooky Cutter can be applied to any Premiere Pro timeline.
          </p>
          <FileUploadSection
            onFileUpload={handleFileUpload}
            isUploading={isUploading}
            uploadError={uploadError}
          />
        </div>
      ) : (
        <div className="center-section col-span-12 flex flex-col text-center justify-center items-center p-8">
          <h2 className="text-2xl font-bold mb-4">Uploading...</h2>
          <p className="text-default-600 mb-8">
            Please wait while we upload your video.
          </p>
        </div>
      )}
    </div>
  );
};

export default UploadFlow;