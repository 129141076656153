import React from 'react';
import {Input} from "@nextui-org/react";

export const FileUploadSection = ({ onFileUpload, isUploading, uploadError, compact, hide }) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      const event = { target: { files: files } };
      onFileUpload(event);
    }
  };

  if (hide) return null;

  return (
    <div className={`upload-section ${compact ? 'p-1' : 'p-4'}`}>
      <div className={compact ? 'flex justify-start' : 'flex justify-center'}>
        <label
          htmlFor="media-upload"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={`cursor-pointer flex flex-col items-center justify-center ${
            compact ? 'px-2 py-1 text-sm' : 'p-6'
          } border-2 border-dashed rounded-lg hover:bg-default-100 transition-colors
          ${isDragging ? 'bg-default-100 border-primary' : ''}`}
        >
          <span className="text-default-600">
            {isUploading ? "Uploading..." : compact ? "New Upload" : "Drop media here or click to upload"}
          </span>
          <span className="text-xs text-default-400 mt-1">
            {!compact && "Supports MP4, MOV, MP3, and WAV files"}
          </span>
          <input
            id="media-upload"
            type="file"
            accept="video/mp4,video/quicktime,audio/mpeg,audio/wav"
            onChange={onFileUpload}
            className="hidden"
          />
        </label>
      </div>
      {uploadError && (
        <div className="text-red-500 mt-2">{uploadError}</div>
      )}
    </div>
  );
};
