import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Input, Button } from '@nextui-org/react';
import { BiLeftArrow, BiRightArrow, BiPlay, BiPause, BiX } from 'react-icons/bi';
import useStore from '../../store';

export const FindBar = ({ onSeek }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { 
    transcriptBlocks, 
    setSelectedWordsInBlock, 
    setSelectedBlockIndex,
    setCurrentBlockIndex,
    setIsTextInputFocused
  } = useStore();
  
  const isPlaying = useStore(state => state.isPlaying);
  const setIsPlaying = useStore(state => state.setIsPlaying);
  const toggleFindBar = useStore(state => state.toggleFindBar);

  // Find all matching word indices across all blocks
  const matches = useMemo(() => {
    if (!searchTerm?.trim()) return [];
    
    const results = [];
    const searchLower = searchTerm.toLowerCase();
    
    transcriptBlocks.forEach((block, blockIndex) => {
      if (!block?.words) return;
      
      block.words.forEach((word, wordIndex) => {
        // Skip silence markers, undefined words, or words without text
        if (!word || word.type === 'silence' || !word.word) return;
        
        // Safely check if the word includes the search term
        const wordText = String(word.word).toLowerCase();
        if (wordText.includes(searchLower)) {
          results.push({ blockIndex, wordIndex, word });
        }
      });
    });
    return results;
  }, [searchTerm, transcriptBlocks]);

  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

  const scrollToBlock = useCallback((blockIndex) => {
    const blockElement = document.querySelector(`[data-block-index="${blockIndex}"]`);
    const transcriptContainer = document.querySelector('.transcript-manager');
    
    if (blockElement && transcriptContainer) {
      const containerRect = transcriptContainer.getBoundingClientRect();
      const elementRect = blockElement.getBoundingClientRect();
      
      const relativeTop = elementRect.top - containerRect.top;
      const centerOffset = (containerRect.height - elementRect.height) / 2;
      const targetScroll = transcriptContainer.scrollTop + relativeTop - centerOffset;
      
      console.log('Container scroll position:', transcriptContainer.scrollTop);
      console.log('Target scroll position:', targetScroll);
      
      transcriptContainer.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });
    }
  }, []);

  const navigateMatch = useCallback((direction) => {
    if (matches.length === 0) return;

    const newIndex = (currentMatchIndex + direction + matches.length) % matches.length;
    setCurrentMatchIndex(newIndex);

    const match = matches[newIndex];
    
    // First set the block selection
    setSelectedBlockIndex(match.blockIndex);
    setCurrentBlockIndex(match.blockIndex);
    
    // Then set the word selection within the block
    setSelectedWordsInBlock({
      [match.blockIndex]: [match.wordIndex]
    });

    // Finally seek to the word's start time
    onSeek(match.word.start);
    
    // Scroll the block into view
    scrollToBlock(match.blockIndex);
  }, [matches, currentMatchIndex, setSelectedBlockIndex, setSelectedWordsInBlock, scrollToBlock, onSeek]);

  const handleSearch = useCallback((e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (!term?.trim()) {
      setCurrentMatchIndex(0);
      return;
    }

    const searchLower = term.toLowerCase();
    const newMatches = [];
    
    transcriptBlocks.forEach((block, blockIndex) => {
      if (!block?.words) return;
      
      block.words.forEach((word, wordIndex) => {
        if (!word || word.type === 'silence' || !word.word) return;
        
        const wordText = String(word.word).toLowerCase();
        if (wordText.includes(searchLower)) {
          newMatches.push({
            blockIndex,
            wordIndex,
            word
          });
        }
      });
    });

    if (newMatches.length > 0) {
      setCurrentMatchIndex(0);
      // Seek to the first match
      onSeek(newMatches[0].word.start);
      // Set selection for the first match
      setSelectedBlockIndex(newMatches[0].blockIndex);
      setSelectedWordsInBlock({
        [newMatches[0].blockIndex]: [newMatches[0].wordIndex]
      });
      // Scroll to the first match
      scrollToBlock(newMatches[0].blockIndex);
    }
  }, [transcriptBlocks, scrollToBlock, onSeek, setSelectedBlockIndex, setSelectedWordsInBlock]);

  const handlePlayPause = useCallback(() => {
    if (!matches.length) return;

    if (!isPlaying) {
      // Start playing from current match
      const match = matches[currentMatchIndex];
      const seekTime = match.word.start;
      onSeek(seekTime);
      
      setSelectedBlockIndex(match.blockIndex);
      setSelectedWordsInBlock({
        [match.blockIndex]: [match.wordIndex]
      });
    }
    
    setIsPlaying(!isPlaying);
  }, [matches, currentMatchIndex, isPlaying, onSeek, setIsPlaying, setSelectedBlockIndex, setSelectedWordsInBlock]);

  const handleClose = useCallback(() => {
    setSearchTerm(''); // Clear search text
    toggleFindBar(); // Close find bar
  }, [toggleFindBar]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  const handleContainerKeyDown = useCallback((e) => {
    // Only stop propagation of WASD keys if they're NOT from the input field
    if (['w', 'a', 's', 'd'].includes(e.key.toLowerCase())) {
      if (e.target.tagName !== 'INPUT') {
        e.stopPropagation();
      }
    }
  }, []);

  useEffect(() => {
    // Clean up focus state when component unmounts
    return () => {
      setIsTextInputFocused(false);
    };
  }, [setIsTextInputFocused]);

  return (
    <div 
      className="find-bar flex items-center gap-2"
      onKeyDown={handleContainerKeyDown}
    >
      <Input
        size="sm"
        placeholder="Find word..."
        value={searchTerm}
        onChange={handleSearch}
        className="max-w-[200px]"
        aria-label="Find word"
        onFocus={(e) => {
          e.target.select();
          setIsTextInputFocused(true);
          console.log('Search input focused');
        }}
        onBlur={() => {
          setSelectedWordsInBlock({});
          setIsTextInputFocused(false);
          console.log('Search input blurred');
        }}
        onKeyDown={(e) => {
          if (!['w', 'a', 's', 'd'].includes(e.key.toLowerCase())) {
            e.continuePropagation();
          }
          handleKeyDown(e);
        }}
      />
      <div className="flex items-center gap-1">
        <Button
          isIconOnly
          size="sm"
          onClick={() => navigateMatch(-1)}
          disabled={matches.length === 0}
        >
          <BiLeftArrow />
        </Button>
        <span className="matches">
          {matches.length > 0 ? `${currentMatchIndex + 1}/${matches.length}` : '0/0'}
        </span>
        <Button
          isIconOnly
          size="sm"
          onClick={() => navigateMatch(1)}
          disabled={matches.length === 0}
        >
          <BiRightArrow />
        </Button>
        <Button
          isIconOnly
          size="sm"
          onClick={handlePlayPause}
          disabled={matches.length === 0}
          className={`ml-2 transition-all duration-300 ease-in-out
            ${isPlaying ? 'bg-sky-200' : 'bg-sky-100/70'} 
            hover:bg-sky-500/60`}
        >
          {isPlaying ? <BiPause /> : <BiPlay />}
        </Button>
        <Button
          isIconOnly
          size="sm"
          onClick={handleClose}
          className="ml-2 hover:bg-gray-500/20"
          aria-label="Close Find Bar"
        >
          <BiX />
        </Button>
      </div>
    </div>
  );
};
