import axios from '../../utils/axios';

/**
 * Checks if a file exists on the server.
 * @param {string} apiUrl - The base API URL
 * @param {string} fileHash - The hash of the file to check
 */
const checkFileExists = async (apiUrl, fileHash) => {
  console.log('FileUploadService: checkFileExists:', { apiUrl, fileHash });
  try {
    // Get token from localStorage or your auth store
    const token = localStorage.getItem('token');
    console.log('Using auth token:', token ? 'Token exists' : 'No token found');

    const response = await axios.get(`/check/${fileHash}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('FileUploadService: checkFileExists: Response:', response.data);
    return response.data.exists;
  } catch (error) {
    console.warn("FileUploadService: checkFileExists: Error:", {
      status: error.response?.status,
      data: error.response?.data
    });
    throw error; // Let the caller handle the error
  }
};

/**
 * Fetches a transcript for a file.
 * @param {string} apiUrl - The base API URL
 * @param {string} fileHash - The hash of the file
 */
const fetchTranscript = async (apiUrl, fileHash) => {
  console.log('FileUploadService: fetchTranscript:', { apiUrl, fileHash });
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`/transcript/${fileHash}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('FileUploadService: fetchTranscript: Response:', response.data);
    
    if (!response.data.transcript) {
      throw new Error('Transcript data not found in the response');
    }
    
    return response.data.transcript;
  } catch (error) {
    console.error('FileUploadService: fetchTranscript: Error:', {
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

const CLOUDFLARE_SAFE_CHUNK_SIZE = 95 * 1024 * 1024; // 95MB to be safe
const REGULAR_UPLOAD_LIMIT = 90 * 1024 * 1024; // 90MB threshold for regular uploads

/**
 * Uploads a file to the server.
 * @param {File} file - The file to upload
 * @param {string} apiUrl - The base API URL
 * @param {string} fileHash - The hash of the file
 */
const uploadFile = async (file, apiUrl, fileHash) => {
  console.log('FileUploadService: uploadFile:', { file, apiUrl, fileHash });
  
  // Use chunked upload for large files
  if (file.size > REGULAR_UPLOAD_LIMIT) {
    return uploadLargeFile(file, fileHash);
  }

  // Regular upload for smaller files
  const formData = new FormData();
  formData.append('file', file);
  formData.append('hash', fileHash);

  try {
    const token = localStorage.getItem('token');
    const response = await axios.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });

    console.log('Upload response:', response.data);
    return response.data;
  } catch (error) {
    console.error('FileUploadService: uploadFile: Error:', {
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

/**
 * Uploads a large file in chunks.
 * @param {File} file - The file to upload
 * @param {string} fileHash - The hash of the file
 */
const uploadLargeFile = async (file, fileHash) => {
  console.log('FileUploadService: uploadLargeFile:', { file, fileHash });
  const totalChunks = Math.ceil(file.size / CLOUDFLARE_SAFE_CHUNK_SIZE);
  
  try {
    const token = localStorage.getItem('token');
    
    // Upload each chunk
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * CLOUDFLARE_SAFE_CHUNK_SIZE;
      const end = Math.min(start + CLOUDFLARE_SAFE_CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);
      
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('hash', fileHash);
      formData.append('chunkIndex', chunkIndex);
      formData.append('totalChunks', totalChunks);
      formData.append('filename', file.name);
      
      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
          'X-Chunk-Upload': 'true'
        },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
      });

      console.log(`Chunk ${chunkIndex + 1}/${totalChunks} uploaded successfully`);
    }

    // Final request to complete the upload
    const finalResponse = await axios.post('/upload/complete', {
      hash: fileHash,
      totalChunks,
      filename: file.name
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    console.log('Upload completed successfully:', finalResponse.data);
    return finalResponse.data;
  } catch (error) {
    console.error('FileUploadService: uploadLargeFile: Error:', {
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

const FileUploadService = {
  checkFileExists,
  fetchTranscript,
  uploadFile,
};

export default FileUploadService;
