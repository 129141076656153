import { useCallback } from 'react';
import useStore from '../../store';

/**
 * Custom hook to manage transcript blocks and their interactions.
 *
 * @param {Function} handleSeek - Function to handle seeking to a specific time in the transcript.
 * @returns {Object} - An object containing various state variables and functions to manage transcript blocks.
 * @returns {Array} transcriptBlocks - Array of transcript blocks.
 * @returns {Function} updateTranscriptBlocks - Function to update the transcript blocks with new data.
 * @returns {Function} setTranscriptBlocks - Function to set the transcript blocks state.
//  * @returns {number} currentBlockIndex - Index of the current block.
 * @returns {Function} setCurrentBlockIndex - Function to set the current block index.
 * @returns {number|null} selectedBlockIndex - Index of the selected block.
 * @returns {Function} setSelectedBlockIndex - Function to set the selected block index.
 * @returns {Function} handleBlockClick - Function to handle clicking on a block. The intent is to seek to the start time of the block.
 * @returns {Function} handleWordNavigation - Function to handle navigation between words within blocks. The block index and word index are provided.
 *
 * TODO: It makes the most sense to break this hook into smaller hooks that manage specific aspects of the transcript block management.
 * The useBlockSplit hook is already extracted, so we can focus on others like block navigation, rejection, and word navigation.
 * By breaking down the functionality into smaller hooks code will be more modular and easier to maintain.
 *
 */
export function useTranscriptBlocks(handleSeek) {
  const {
    transcriptBlocks,
    setTranscriptBlocks,
    currentBlockIndex,
    setCurrentBlockIndex,
    selectedBlockIndex,
    setSelectedBlockIndex,
  } = useStore();

  const handleRejectBlock = useCallback((index) => {
    setTranscriptBlocks(prevBlocks => {
      const newBlocks = [...prevBlocks];
      newBlocks[index].isRejected = !newBlocks[index].isRejected;
      return newBlocks;
    });
  }, [setTranscriptBlocks]);

  /**
   * Handles the click event on a transcript block.
   *
   * @param {number} index - The index of the clicked block.
   * @param {number} startTime - The start time of the clicked block.
   */
  const handleBlockClick = useCallback((index, startTime) => {
    // setSelectedBlockIndex(index);
    // setCurrentBlockIndex(index);
    // handleSeek(startTime + 0.001);
  }, [handleSeek]);


  return {
    transcriptBlocks,
    setTranscriptBlocks,
    // currentBlockIndex,
    setCurrentBlockIndex,
    selectedBlockIndex,
    setSelectedBlockIndex,
    handleRejectBlock,
    handleBlockClick,
  };
}

export default useTranscriptBlocks;