import React, { useRef } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, ButtonGroup, Checkbox } from "@nextui-org/react";
import { BiDownload as DownloadIcon, BiCopy as CopyIcon} from "react-icons/bi";
import useStore from '../../store';
import { useJSONChange } from '../../hooks';

export const ExportPremiereModal = ({ isOpen, onClose }) => {
  const textAreaRef = useRef(null);
  const { 
    copyStatus, 
    setCopyStatus, 
    includeRejectedBlocksInJSON, 
    setIncludeRejectedBlocksInJSON 
  } = useStore();
  
  const jsonOutput = useJSONChange();

  const handleCopyJson = () => {
    if (textAreaRef.current) {
      navigator.clipboard.writeText(textAreaRef.current.value)
        .then(() => {
          setCopyStatus('Copied!');
          setTimeout(() => setCopyStatus('Copy'), 2000);
        })
        .catch(err => console.error('Failed to copy text: ', err));
      setCopyStatus('Copied!');
      setTimeout(() => setCopyStatus('Copy'), 2000);
    }
  };

  const handleDownloadJson = () => {
    const element = document.createElement('a');
    const file = new Blob([jsonOutput], { type: 'application/json' });
    element.href = URL.createObjectURL(file);
    element.download = 'transcript.json';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalContent>
        <ModalHeader>Export for Premiere</ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <Checkbox
                isSelected={includeRejectedBlocksInJSON}
                onValueChange={setIncludeRejectedBlocksInJSON}
              >
                Include Rejected Blocks
              </Checkbox>
              <ButtonGroup>
                <Button 
                  onClick={handleDownloadJson} 
                  className="bg-default" 
                  disabled={jsonOutput === ''} 
                  startContent={<DownloadIcon className="text-xl" />}
                >
                  Download JSON
                </Button>
                <Button 
                  onClick={handleCopyJson} 
                  className="bg-default" 
                  disabled={jsonOutput === ''} 
                  startContent={<CopyIcon className="text-xl" />}
                >
                  {copyStatus === 'Copied!' ? 'Copied!' : 'Copy JSON'}
                </Button>
              </ButtonGroup>
            </div>

            <div className="mt-4">
              <textarea
                ref={textAreaRef}
                value={jsonOutput}
                readOnly
                className="json-textarea w-full min-h-[300px] font-mono resize-vertical bg-black/20 rounded p-2"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}; 