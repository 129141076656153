// Fullscreen Button Component
import React from 'react';
import { useFullscreen } from '../../hooks';
import { Button } from "@nextui-org/button";
import { BiFullscreen as FullscreenIcon, BiExitFullscreen as ExitFullscreenIcon } from "react-icons/bi";

export const FullscreenButton = () => {
  const { isFullscreen, toggleFullscreen } = useFullscreen();

  return (
    <Button 
      onClick={toggleFullscreen} 
      className="fullscreen-button text-2xl !bg-light-bg dark:!bg-dark-bg hover:!bg-slate-100 dark:hover:!bg-slate-700" 
      aria-label="Toggle Fullscreen" 
      title={isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
      isIconOnly
    >
      {isFullscreen ? <ExitFullscreenIcon /> : <FullscreenIcon />}
    </Button>
  );
};