import React, { useEffect, useMemo, memo, useCallback } from 'react';
import useProjects from '../../hooks/useProjects';
import AuthImage from '../AuthImage';
import { Button, Card, CardBody } from '@nextui-org/react';
import { ProjectDeleteButton } from '../ProjectDeleteButton';

const ProjectThumbnail = memo(({ videoSHA, projectName, variant = 'compact' }) => (
  <div className={`${
    variant === 'expanded' 
      ? 'w-48 aspect-video' 
      : 'w-16 h-9'
  } bg-default-200 rounded flex-shrink-0 overflow-hidden`}>
    <AuthImage 
      fileHash={videoSHA}
      alt={`Thumbnail for ${projectName}`}
      className="w-full h-full object-cover"
      onError={(error) => {
        console.error('Failed to load thumbnail:', videoSHA);
      }}
    />
  </div>
));

const ProjectItem = memo(({ project, onProjectClick, variant = 'compact', hideInputs = false }) => {
  const formatTimestamp = (filename) => {
    const match = filename.match(/(\d+)\.json$/);
    if (!match) return '';
    const timestamp = parseInt(match[1]);
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const displayName = project.projectName || 'Untitled Cut';
  const timestamp = formatTimestamp(project.filename);
  const cutId = project.filename.replace('.json', '');

  if (variant === 'expanded') {
    return (
      <li className="group hover:bg-default-100 p-4 rounded relative">
        <button
          onClick={() => onProjectClick(project)}
          className="text-left w-full"
        >
          <div className="flex gap-4 items-start">
            <ProjectThumbnail 
              videoSHA={project.videoSHA} 
              projectName={displayName}
              variant="expanded"
            />
            <div className="flex flex-col gap-2">
              <h3 className="text-xl font-medium">
                {displayName}
              </h3>
              <div className="text-default-600">
                Modified: {timestamp}
              </div>
              {!hideInputs && (
                <div className="font-mono text-sm text-default-500">
                  Cut ID: {cutId}
                </div>
              )}
            </div>
          </div>
        </button>
        <ProjectDeleteButton 
          videoSHA={project.videoSHA}
          filename={project.filename}
        />
      </li>
    );
  }

  return (
    <li className="group hover:bg-default-100 p-2 rounded relative">
      <button
        onClick={() => onProjectClick(project)}
        className="text-left w-full flex gap-3 items-center pr-8"
      >
        <ProjectThumbnail 
          videoSHA={project.videoSHA} 
          projectName={displayName}
          variant="compact"
        />
        <div className="flex flex-col min-w-0">
          <span className="font-medium truncate">
            {displayName}
          </span>
          <span className="text-xs text-default-400">
            Modified: {timestamp}
          </span>
          {!hideInputs && (
            <span className="text-xs text-default-500 font-mono">
              Cut ID: {cutId}
            </span>
          )}
        </div>
      </button>
      <ProjectDeleteButton 
        videoSHA={project.videoSHA}
        filename={project.filename}
      />
    </li>
  );
});

const ProjectBrowser = memo(({ onLoadProject, filterBySHA, variant = 'compact', hideInputs = false }) => {
  const { fetchFiles, files } = useProjects();

  useEffect(() => {
    fetchFiles(filterBySHA);
    
    // Add event listeners for project updates
    const handleProjectSaved = () => {
      console.log('Caught projectSaved event, refreshing files');
      fetchFiles(filterBySHA);
    };
    
    const handleProjectDeleted = () => {
      console.log('Caught projectDeleted event, refreshing files');
      fetchFiles(filterBySHA);
    };
    
    console.log('Adding project event listeners');
    window.addEventListener('projectSaved', handleProjectSaved);
    window.addEventListener('projectDeleted', handleProjectDeleted);
    
    // Cleanup
    return () => {
      console.log('Removing project event listeners');
      window.removeEventListener('projectSaved', handleProjectSaved);
      window.removeEventListener('projectDeleted', handleProjectDeleted);
    };
  }, [fetchFiles, filterBySHA]);

  const filteredFiles = useMemo(() => {
    if (!filterBySHA) return files;
    return files.filter(f => f.videoSHA === filterBySHA);
  }, [files, filterBySHA]);

  const handleRefresh = useCallback(() => {
    fetchFiles(filterBySHA);
  }, [fetchFiles, filterBySHA]);

  const content = (
    <div className={`h-full overflow-hidden col-span-12 grid grid-cols-12 grid-rows-[auto,1fr] 
      ${variant === 'expanded' ? 'gap-6 pt-5 max-w-5xl mx-auto' : ''}`}>
      <div className="flex justify-between items-center mb-4 col-span-12 px-4">
        <h2 className={`${variant === 'expanded' ? 'text-2xl' : 'text-xl'}`}>
          {hideInputs ? 'Available Cuts' : 'Available Cuts'}
        </h2>
        {!variant === 'expanded' && (
          <Button 
            size={variant === 'expanded' ? 'md' : 'sm'}
            onClick={handleRefresh}
            className="bg-default-100 hover:bg-default-200"
          >
            Refresh List
          </Button>
        )}
      </div>
      {filteredFiles.length === 0 ? (
        <div className="text-default-600">
          {/* Loading text removed */}
        </div>
      ) : (
        <div className='h-full overflow-hidden col-span-12'>
          <ul className={`h-full overflow-y-auto ${
            variant === 'expanded' ? 'grid grid-cols-1 max-w-4xl mx-auto' : ''
          }`}>
            {filteredFiles.map((project) => (
              <ProjectItem 
                key={project.videoSHA + project.filename} 
                project={project} 
                onProjectClick={onLoadProject}
                variant={variant}
                hideInputs={hideInputs}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  if (hideInputs) {
    return (
      <Card>
        <CardBody>
          {content}
        </CardBody>
      </Card>
    );
  }

  return content;
}, (prevProps, nextProps) => {
  return prevProps.filterBySHA === nextProps.filterBySHA &&
         prevProps.onLoadProject === nextProps.onLoadProject &&
         prevProps.hideInputs === nextProps.hideInputs;
});

export default ProjectBrowser;