import { useCallback } from 'react';

import { preventOverlap } from '../../utils/TranscriptUtils';

import { TranscriptProcessorService } from '../../services';
import useStore from '../../store';



/**
 * Custom hook to process transcripts.
 * @param {boolean} breakAtPunctuation - Whether to break at punctuation.
 * @param {number} useMaxCharacters - Maximum characters per section.
 * @param {boolean} breakOnSpeakerChange - Whether to break on speaker change.
 * @param {boolean} rejectLongSilences - Whether to reject long silences.
 * @param {boolean} breakOnLongSilences - Whether to break on long silences.
 * @param {number} longSilenceThreshold - Long silence threshold in seconds.
 * @param {boolean} rejectBlocksContaining - Whether to reject blocks containing.
 * @param {string} rejectBlocksCondition - Reject blocks condition.
 * @param {string} rejectBlocksWord - Reject blocks word.
 * @returns {Function} - The handleTranscript function.
 */
export function useTranscriptProcessor() {
  const {
    breakAtPunctuation,
    useMaxCharacters,
    maxCharPerSection,
    breakOnSpeakerChange,
    rejectLongSilences,
    breakOnLongSilences,
    longSilenceThreshold,
    rejectBlocksContaining,
    rejectBlocksCondition,
    rejectBlocksWord,
    currentLanguage,
    setSourceLanguage,
    setAvailableLanguages,
    setTranscriptBlocks,
    setUploadError
  } = useStore();

  const handleTranscript = useCallback((transcriptData) => {
    // Early return if we don't have valid data
    if (!transcriptData || typeof transcriptData !== 'object' || !transcriptData.words) {
      return;
    }

    // Only process if we have actual transcript data
    if (transcriptData.task !== 'transcribe') {
      return;
    }

    console.log('Raw transcript data structure:', {
      hasWords: !!transcriptData.words,
      hasData: !!transcriptData.data,
      hasTranscript: !!transcriptData.transcript,
      language: transcriptData.language,
      duration: transcriptData.duration,
      hasTranslations: !!transcriptData.translations,
      hasText: !!transcriptData.text
    });
    
    // Try to find words in the data structure
    let extractedWords = [];
    if (transcriptData.words) {
      extractedWords = transcriptData.words;
      console.log('Found words in root');
    } else if (transcriptData.data?.words) {
      extractedWords = transcriptData.data.words;
      console.log('Found words in data');
    } else if (transcriptData.transcript?.words) {
      extractedWords = transcriptData.transcript.words;
      console.log('Found words in transcript');
    }

    console.log('Words array stats:', {
      length: extractedWords.length,
      isArray: Array.isArray(extractedWords),
      sampleWord: extractedWords[0]
    });
    
    const { 
      language = 'english', 
      duration, 
      translations,
      text,
      dateCreated,
      task
    } = transcriptData;

    try {
      // Don't throw on empty array, but log it
      if (!extractedWords || !Array.isArray(extractedWords)) {
        console.error('Words is not an array:', typeof extractedWords, extractedWords);
        throw new Error('Words must be an array in transcript data');
      }

      if (extractedWords.length === 0) {
        console.log('Creating single word block with:', {
          text,
          duration,
          translations
        });
        if (text) {
          extractedWords = [{
            word: text,
            start: 0,
            end: duration || 0,
            translations: translations || {}
          }];
        }
      }

      // Process words with translations
      const processedWords = extractedWords.map(word => {
        if (!word) {
          console.warn('Encountered null or undefined word');
          return null;
        }
        
        return {
          ...word,
          displayWord: (currentLanguage !== language && 
                       word.translations?.[currentLanguage]) || word.word,
          originalWord: word.word,
          confidence: 1,
          speaker: null,
          start: word.start || 0,
          end: word.end || 0
        };
      }).filter(Boolean);

      console.log('Processed words array:', processedWords);

      if (processedWords.length === 0) {
        console.warn('No valid words to process');
        return;
      }

      const groups = TranscriptProcessorService.processTranscriptToGroups(
        { words: processedWords, duration }, 
        breakAtPunctuation,
        useMaxCharacters,
        maxCharPerSection,
        breakOnSpeakerChange,
        rejectLongSilences,
        breakOnLongSilences,
        longSilenceThreshold,
        duration,
        rejectBlocksContaining,
        rejectBlocksCondition,
        rejectBlocksWord
      );

      console.log('Processed groups:', groups);

      const adjustedGroups = preventOverlap(groups);
      console.log('Adjusted groups:', adjustedGroups);

      setTranscriptBlocks(adjustedGroups);
      
      // Handle language information
      setSourceLanguage(language);
      
      // Set available translations
      if (translations && typeof translations === 'object') {
        const availableLangs = Object.entries(translations)
          .map(([code, data]) => ({
            code,
            dateCreated: data.dateCreated
          }));
        setAvailableLanguages(availableLangs);
      } else {
        setAvailableLanguages([]);
      }

    } catch (error) {
      console.error('Error processing transcript:', {
        error,
        transcriptDataType: typeof transcriptData,
        hasWords: !!transcriptData.words,
        hasText: !!transcriptData.text
      });
      setUploadError('Error processing transcript. Please try again.');
    }
  }, [
    breakAtPunctuation, 
    useMaxCharacters, 
    maxCharPerSection, 
    breakOnSpeakerChange, 
    rejectLongSilences, 
    breakOnLongSilences, 
    longSilenceThreshold,
    rejectBlocksContaining,
    rejectBlocksCondition,
    rejectBlocksWord,
    currentLanguage,
    setSourceLanguage,
    setAvailableLanguages,
    setTranscriptBlocks,
    setUploadError
  ]);

  return handleTranscript;
};

