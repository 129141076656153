import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress, FaExternalLinkAlt } from 'react-icons/fa';
import { formatTime } from '../../utils/timeUtils';
import './VideoPlayer.css';
import { useSequentialPlaybackStore } from '../../store/sequentialPlaybackStore';
import useStore from '../../store';

const getMediaType = (src) => {
  const url = typeof src === 'string' ? src : src?.url;
  if (!url) return 'unknown';
  
  const extension = url.split('.').pop().toLowerCase();
  if (['mp4'].includes(extension)) return 'video/mp4';
  if (['mov'].includes(extension)) return 'video/quicktime';
  if (['mp3'].includes(extension)) return 'audio/mp3';
  if (['wav'].includes(extension)) return 'audio/wav';
  return 'unknown';
};

export const VideoPlayer = ({
  videoPlayerRef,
  src,
  onSeek,
  onPlayPause,
  onTimeUpdate,
  currentBlock,
  isPlaying,
  isSeeking,
  handleStoppingOnRejectedBlock
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);
  const [isPiP, setIsPiP] = useState(false);
  const playerRef = useRef(null);
  const handleBlockEnd = useSequentialPlaybackStore(state => state.handleBlockEnd);
  const transcriptBlocks = useStore(state => state.transcriptBlocks);
  const currentBlockIndex = useStore(state => state.currentBlockIndex);
  const setCurrentBlockIndex = useStore(state => state.setCurrentBlockIndex);
  const setSelectedBlockIndex = useStore(state => state.setSelectedBlockIndex);
  const [videoBlob, setVideoBlob] = useState(null);
  const [mediaType, setMediaType] = useState(null);

  useImperativeHandle(videoPlayerRef, () => ({
    seekTo: (seconds) => {
      if (playerRef.current) {
        playerRef.current.seekTo(seconds ?? 0, 'seconds');
      }
    },
    play: () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().play();
      }
    },
    pause: () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().pause();
      }
    },
    getCurrentTime: () => playerRef.current ? playerRef.current.getCurrentTime() : 0,
  }));

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
    onTimeUpdate(state.playedSeconds);
    
    if (isPlaying && currentBlock && state.playedSeconds >= currentBlock.end) {
      const nextBlock = transcriptBlocks[currentBlockIndex + 1];
      handleBlockEnd(
        currentBlock, 
        nextBlock,
        onSeek,
        () => setCurrentBlockIndex(currentBlockIndex + 1),
        () => setSelectedBlockIndex(currentBlockIndex + 1),
        onPlayPause,
        transcriptBlocks
      );
    }
  };

  const handleDuration = (duration) => {
    console.log('Video duration set:', duration);
    setDuration(duration);
  };

  const handleSeekChange = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const percentage = x / rect.width;
    const seekTime = percentage * duration;
    
    if (playerRef.current) {
      playerRef.current.seekTo(seekTime, 'seconds');
      setCurrentTime(seekTime);
      onSeek?.(seekTime);
    }
  };

  const toggleFullscreen = async () => {
    if (!document.fullscreenElement) {
      try {
        await containerRef.current.requestFullscreen();
        setIsFullscreen(true);
      } catch (err) {
        console.error('Error attempting to enable fullscreen:', err);
      }
    } else {
      await document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const togglePiP = async () => {
    const videoElement = playerRef.current?.getInternalPlayer();
    if (!videoElement) return;

    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
        setIsPiP(false);
      } else if (document.pictureInPictureEnabled) {
        await videoElement.requestPictureInPicture();
        setIsPiP(true);
      }
    } catch (err) {
      console.error('Error toggling Picture-in-Picture:', err);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    const loadVideo = async () => {
      try {
        if (typeof src === 'string' ? src.includes('/api/project/') : src.url?.includes('/api/project/')) {
          const token = localStorage.getItem('authToken');
          const videoUrl = typeof src === 'string' ? src : src.url;
          
          console.log('Attempting to load video from:', videoUrl);
          console.log('Auth token present:', !!token);
          
          // Create a URL with the auth token
          const urlWithAuth = new URL(videoUrl, window.location.origin);
          urlWithAuth.searchParams.append('token', token);
          
          console.log('Using video URL with auth:', urlWithAuth.toString());
          setVideoBlob(urlWithAuth.toString());
          
        } else {
          const directUrl = src.url || src;
          console.log('Using direct URL for video:', directUrl);
          setVideoBlob(directUrl);
        }
      } catch (error) {
        console.error('Error setting up video:', {
          error,
          src: typeof src === 'string' ? src : src?.url,
          type: src.url?.includes('/api/project/') ? 'backend' : 'uploaded'
        });
      }
    };

    if (src?.url || typeof src === 'string') {
      loadVideo();
    }

    return () => {
      if (videoBlob?.startsWith('blob:')) {
        URL.revokeObjectURL(videoBlob);
      }
    };
  }, [src]);

  useEffect(() => {
    setMediaType(getMediaType(src));
  }, [src]);

  return (
    <div 
      className="video-player" 
      ref={containerRef}
      data-is-fullscreen={isFullscreen}
      data-current-block={currentBlock?.id}
    >
      <div onClick={() => onPlayPause(!isPlaying)}>
        {videoBlob ? (
          mediaType?.startsWith('audio/') ? (
            // Audio player
            <audio
              ref={playerRef}
              src={videoBlob}
              style={{ width: '100%' }}
              onTimeUpdate={(e) => handleProgress({ playedSeconds: e.target.currentTime })}
              onDurationChange={(e) => handleDuration(e.target.duration)}
              onPlay={() => onPlayPause(true)}
              onPause={() => onPlayPause(false)}
            />
          ) : (
            // Video player with fallback for MOV files
            mediaType === 'video/quicktime' ? (
              <video
                ref={playerRef}
                src={videoBlob}
                style={{ width: '100%', height: 'auto' }}
                playsInline
                onTimeUpdate={(e) => handleProgress({ playedSeconds: e.target.currentTime })}
                onDurationChange={(e) => handleDuration(e.target.duration)}
                onPlay={() => onPlayPause(true)}
                onPause={() => onPlayPause(false)}
              />
            ) : (
              <ReactPlayer
                ref={playerRef}
                url={videoBlob}
                playing={isPlaying}
                controls={false}
                volume={volume}
                muted={isMuted}
                width="100%"
                height="auto"
                playsinline
                onPlay={() => onPlayPause(true)}
                onPause={() => onPlayPause(false)}
                onError={(e) => {
                  console.error('ReactPlayer error:', e);
                  if (e.target) {
                    console.log('Video element details:', {
                      error: e.target.error,
                      readyState: e.target.readyState,
                      networkState: e.target.networkState,
                      src: e.target.src,
                      type: mediaType
                    });
                  }
                }}
                onProgress={handleProgress}
                onDuration={handleDuration}
                progressInterval={10}
              />
            )
          )
        ) : (
          <div className="loading-placeholder">Loading media...</div>
        )}
      </div>
      
      <div className="custom-controls flex items-center gap-2 p-2 bg-gray-800/50 rounded-b">
        <button 
          onClick={() => onPlayPause(!isPlaying)}
          className="p-2 hover:bg-gray-700/50 rounded"
          title={isPlaying ? "Pause" : "Play"}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>

        <div 
          className="flex-grow relative h-1 bg-gray-600 rounded cursor-pointer"
          onClick={handleSeekChange}
        >
          <div 
            className="absolute h-full bg-blue-500 rounded"
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
          <div className="absolute h-full hover:bg-white/10 w-full rounded transition-all" />
        </div>

        <div className="text-sm font-mono">
          {formatTime(currentTime)} / {formatTime(duration)}
        </div>

        <button 
          onClick={() => setIsMuted(!isMuted)}
          className="p-2 hover:bg-gray-700/50 rounded"
          title={isMuted ? "Unmute" : "Mute"}
        >
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </button>
        
        <input 
          type="range"
          min={0}
          max={1}
          step={0.1}
          value={volume}
          onChange={(e) => setVolume(parseFloat(e.target.value))}
          className="w-20"
          title="Volume"
        />

        <button 
          onClick={togglePiP}
          className="p-2 hover:bg-gray-700/50 rounded text-white"
          title="Picture in Picture"
        >
          <FaExternalLinkAlt />
        </button>

        <button 
          onClick={toggleFullscreen}
          className="p-2 hover:bg-gray-700/50 rounded text-white"
          title={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
        >
          {isFullscreen ? <FaCompress /> : <FaExpand />}
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
