import { useCallback, useEffect, useMemo } from 'react';
import useStore from '../../store';

export function useBlockHandlers(handleSeek, currentTime, onSelectedWordsChange) {
  const {
    currentBlockIndex,
    setCurrentBlockIndex,
    setSelectedBlockIndex,
    toggleBlockRejection,
    groupTranscriptBlocks,
    selectedWordsInBlock,
    setSelectedWords,
    getCurrentWordIndex,
    transcriptBlocks,
  } = useStore();

  const handleBlockSelection = useCallback((index, startTime, shouldSeek = false) => {
    console.log('useBlockHandlers :: handleBlockSelection() - called with:', {
      index,
      startTime,
      shouldSeek
    });
    
    setCurrentBlockIndex(index);
    setSelectedBlockIndex(index);
    
    if (shouldSeek) {
      console.log('Triggering seek to:', startTime + 0.001);
      handleSeek(startTime + 0.001);
    }
  }, [setSelectedBlockIndex, setCurrentBlockIndex, handleSeek]);

  const handleBlockRejection = useCallback((index) => {
    console.log('useBlockHandlers :: handleBlockRejection() - original index:', index);
    
    toggleBlockRejection(index);
    const updatedBlocks = groupTranscriptBlocks();
    
    const blockAtOriginalIndex = updatedBlocks[index];
    
    if (blockAtOriginalIndex && !blockAtOriginalIndex.isRejected) {
      console.log('Seeking to block at original index:', {
        index,
        block: blockAtOriginalIndex,
        startTime: blockAtOriginalIndex.words[0].start
      });
      handleSeek(blockAtOriginalIndex.words[0].start + 0.001);
    }
  }, [toggleBlockRejection, groupTranscriptBlocks, handleSeek]);

  const clearSelection = useCallback(() => {
    onSelectedWordsChange([]);
  }, [onSelectedWordsChange]);

  const handleWordNavigation = useCallback((newIndex, blockIndex, onWordNavigation) => {
    console.log('useBlockHandlers :: handleWordNavigation() - newIndex:', newIndex, 'blockIndex:', blockIndex);
    clearSelection();
    onWordNavigation(newIndex, blockIndex);
  }, [clearSelection]);

  const handleMouseDown = useCallback((index, onBlockSelect, setIsSelecting, setSelectionStart) => {
    console.log('useBlockHandlers :: handleMouseDown()');
    onBlockSelect(index);
    setIsSelecting(true);
    setSelectionStart(index);
  }, []);

  const handleMouseEnter = useCallback((index, isSelecting, selectionStart) => {
    console.log('useBlockHandlers :: handleMouseEnter()');
    if (isSelecting) {
      setSelectedWords(selectionStart, index);
    }
  }, [setSelectedWords]);

  const handleMouseUp = useCallback((setIsSelecting) => {
    console.log('useBlockHandlers :: handleMouseUp()');
    setIsSelecting(false);
  }, []);

  const handleClick = useCallback((event, onBlockSelect, onClick) => {
    console.log('useBlockHandlers :: handleClick() - event:', event);
    onBlockSelect(event);
    onClick(event);
  }, []);

  return {
    handleBlockSelection,
    getCurrentWordIndex,
    handleBlockRejection,
    clearSelection,
    handleWordNavigation,
    handleSeek,
    handleMouseDown,
    handleMouseEnter,
    handleMouseUp,
    handleClick,
  };
}

export default useBlockHandlers;