export const shortcutGroups = {
  navigation: {
    title: 'Navigation',
    shortcuts: [
      {
        keys: ['W', 'A', 'S', 'D', '←', '→'],
        description: 'Navigate between words'
      },
      {
        keys: ['↑', '↓'],
        description: 'Navigate between blocks'
      },
      {
        keys: ['Space'],
        description: 'Play/Pause'
      },
      {
        keys: ['F'],
        description: 'Toggle Find Bar'
      },
      {
        keys: ['T'],
        description: 'Toggle Word Editor'
      }
    ]
  },
  editing: {
    title: 'Editing',
    shortcuts: [
      {
        keys: ['⌘', 'Z'],
        description: 'Undo'
      },
      {
        keys: ['⌘', '⇧', 'Z'],
        description: 'Redo'
      },
      {
        keys: ['⌘', 'D'],
        description: 'Duplicate Block'
      },
      {
        keys: ['⌘', 'R'],
        description: 'Delete Block Permanently'
      },
      {
        keys: ['R', 'Delete', 'Backspace'],
        description: 'Reject Block/Selection'
      },
      {
        keys: ['E', 'K'],
        description: 'Split Block at Current Word or by Selection'
      },
      {
        keys: ['I'],
        description: 'Isolate Selected Words'
      },
      {
        keys: ['J'],
        description: 'Split and Reject Left'
      },
      {
        keys: ['⇧', 'J'],
        description: 'Reject All Previous Blocks'
      },
      {
        keys: ['L'],
        description: 'Split and Reject Right'
      },
      {
        keys: ['⇧', 'L'],
        description: 'Reject All Following Blocks'
      }
    ]
  }
};
