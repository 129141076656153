
import { useState, useEffect, useRef } from 'react';
import useStore from '../../store';
import { JSONUpdateService } from '../../services';

/**
 * Custom hook to generate and update JSON output based on transcript blocks and rejection index.
 *
 * @param {Array} transcriptBlocks - Array of transcript blocks to be processed.
 * @param {number} rejectionIndex - Index indicating the rejection point in the transcript blocks.
 * @returns {string} - The generated JSON output.
 */
export function useJSONChange() {
  const { transcriptBlocks, includeRejectedBlocksInJSON } = useStore();
  const [jsonOutput, setJsonOutput] = useState('');
  // const JSONOutputRef = useRef('');
  // const [, setTrigger] = useState(0); // State to trigger re-render

  useEffect(() => {
    const output= JSONUpdateService.generateJSONOutput(transcriptBlocks, includeRejectedBlocksInJSON);
    setJsonOutput(output);
  }, [transcriptBlocks, includeRejectedBlocksInJSON]);

  return jsonOutput;
}

export default useJSONChange;
