import { useCallback } from 'react';
import useStore from '../../store';
import { useBlockHandlers } from '../useBlockHandlers';

export function useBlockSplit(handleSeek) {
  const { 
    splitBlock, 
    splitBlockAndRejectSelectedWords,
    currentBlockIndex,
    transcriptBlocks,
    getCurrentWordIndex,
  } = useStore();

  const { handleBlockSelection } = useBlockHandlers(handleSeek);

  const handleSplit = useCallback(() => {
    splitBlock();
  }, [splitBlock]);

  const handleSplitAndReject = useCallback(() => {
    const currentIndex = currentBlockIndex;
    
    splitBlockAndRejectSelectedWords();
    
    setTimeout(() => {
      const block = transcriptBlocks[currentIndex];
      if (block) {
        handleBlockSelection(currentIndex, block.words[0].start, true);
        handleSeek(block.words[0].start + 0.001);
      }
    }, 0);
  }, [splitBlockAndRejectSelectedWords, currentBlockIndex, transcriptBlocks, handleBlockSelection, handleSeek]);

  const handleSplitAndRejectLeft = useCallback(() => {
    const currentIndex = currentBlockIndex;
    const currentBlock = transcriptBlocks[currentIndex];
    const currentWordIndex = getCurrentWordIndex();
    
    const targetTime = currentBlock.words[currentWordIndex]?.start || currentBlock.words[0].start;
    
    splitBlock('left', false);
    
    setTimeout(() => {
      const newBlock = transcriptBlocks[currentIndex];
      if (newBlock) {
        const targetWordIndex = newBlock.words.findIndex(w => w.start >= targetTime);
        const seekTime = newBlock.words[targetWordIndex]?.start || newBlock.words[0].start;
        
        handleBlockSelection(currentIndex, seekTime, true);
        handleSeek(seekTime + 0.001);
      }
    }, 50);
  }, [splitBlock, currentBlockIndex, transcriptBlocks, handleBlockSelection, handleSeek, getCurrentWordIndex]);

  const handleSplitAndRejectRight = useCallback(() => {
    splitBlock('right', true);  // Set splitAfterCurrent to true for 'L' key
  }, [splitBlock]);

  return { handleSplit, handleSplitAndReject, handleSplitAndRejectLeft, handleSplitAndRejectRight };
}

export default useBlockSplit;
