import { useCallback } from 'react';
import useStore from '../../store';

export function useBlockNavigation(handleSeek, handleVideoPlayPause) {
  const { transcriptBlocks, currentBlockIndex, setCurrentBlockIndex, setSelectedBlockIndex, isPlaying } = useStore();

  const handleBlockNavigation = useCallback((direction, options = {}) => {
    console.log('useBlockNavigation :: handleBlockNavigation() - direction:', direction, 'options:', options, 'transcriptBlocks:', transcriptBlocks);
    if (transcriptBlocks.length === 0) {
      console.warn('No transcript blocks available for navigation');
      return;
    }

    const newIndex = currentBlockIndex + direction;
    if (newIndex < 0 || newIndex >= transcriptBlocks.length) {
      console.warn('New block index out of bounds');
      return;
    }
    
    const targetBlock = transcriptBlocks[newIndex];
    if (!targetBlock.words.length) {
      console.warn('No words in target block');
      return;
    }

    // If moving left (direction = -1) and selectLastWord is true, use the last word
    const targetWord = options.selectLastWord ? 
      targetBlock.words[targetBlock.words.length - 1] :
      targetBlock.words[0];

    const startTime = targetWord.start;
    console.log('Navigation to block', newIndex, 'with start time:', startTime);
    
    setCurrentBlockIndex(newIndex);
    setSelectedBlockIndex(newIndex);
    handleSeek(startTime + 0.001);
  }, [transcriptBlocks, currentBlockIndex, setCurrentBlockIndex, setSelectedBlockIndex, handleSeek]);

  const handleBlockEnd = useCallback((endedBlockId) => {
    if (!isPlaying) return;

    const endedBlockIndex = transcriptBlocks.findIndex(block => block.id === endedBlockId);
    if (endedBlockIndex === -1) return;

    const currentBlock = transcriptBlocks[endedBlockIndex];
    const wasPlayingRejectedBlock = currentBlock.isRejected;
    
    // Find the next block (rejected or not, depending on current context)
    let nextIndex = endedBlockIndex + 1;
    
    // If we were playing a rejected block, continue through rejected blocks
    // Otherwise, skip rejected blocks
    if (!wasPlayingRejectedBlock) {
      while (nextIndex < transcriptBlocks.length && transcriptBlocks[nextIndex].isRejected) {
        nextIndex++;
      }
    }

    // If we've reached the end, loop back to beginning and pause
    if (nextIndex >= transcriptBlocks.length) {
      setCurrentBlockIndex(0);
      setSelectedBlockIndex(0);
      handleSeek(transcriptBlocks[0].start + 0.001);
      handleVideoPlayPause(false); // Pause at the beginning
      return;
    }

    const nextBlock = transcriptBlocks[nextIndex];
    
    if (isPlaying) {
      setCurrentBlockIndex(nextIndex);
      setSelectedBlockIndex(nextIndex);
      handleSeek(nextBlock.start + 0.001);
    }

  }, [transcriptBlocks, handleSeek, handleVideoPlayPause, setCurrentBlockIndex, setSelectedBlockIndex, isPlaying]);

  return { handleBlockNavigation, handleBlockEnd };
}

export default useBlockNavigation;
