import { useCallback } from 'react';
import useStore from '../../store';
import { useTranscriptProcessor } from '../../hooks';
import { FileUploadService } from '../../services';

// Function to calculate SHA-256 hash of a file
const calculateFileHash = async (file) => {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};

/**
 * Custom hook for handling file uploads.
 * @returns {Object} - An object containing the upload state and handlers.
 */
export function useFileUpload() {
  const {
    isUploading,
    uploadError,
    setUploadError,
    setIsUploading,
    setTranscriptData,
    videoSHA,
    setVideoSHA
  } = useStore();

  const apiUrl = '/api';

  const setVideoFile = useStore(state => state.setVideoFile);
  // const setVideoProcessed = useStore(state => state.setVideoProcessed);
  const setVideoFileName = useStore(state => state.setVideoFileName);
  // const videoFile = useStore(state => state.videoFile);

  const handleFileUpload = useCallback(async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (setVideoFileName && typeof setVideoFileName === 'function') {
      setVideoFileName(file.name);
    }

    setIsUploading(true);
    setUploadError(null);

    if (setVideoFile && typeof setVideoFile === 'function') {
      setVideoFile(URL.createObjectURL(file));
    }

    try {
      const hash = await calculateFileHash(file);
      setVideoSHA(hash);
      console.log('Calculated file hash:', hash);

      const fileExists = await FileUploadService.checkFileExists(apiUrl, hash);
      if (fileExists) {
        try {
          const data = await FileUploadService.fetchTranscript(apiUrl, hash);
          setTranscriptData(data);
        } catch (fetchError) {
          console.warn("Failed to fetch existing transcript, uploading file instead:", fetchError);
        }
      }

      if (!fileExists) {
        console.log('File not found on server. Uploading file...');
        const uploadResponse = await FileUploadService.uploadFile(file, apiUrl, hash);
        setTranscriptData(uploadResponse.transcript);
      }

    } catch (error) {
      setUploadError(error.message);
      console.error("File upload error:", error);
    } finally {
      setIsUploading(false);
    }
  }, [apiUrl, setVideoFile, setVideoFileName, setIsUploading, setUploadError, setTranscriptData, setVideoSHA]);

  const handleTranscript = useTranscriptProcessor();

  const generateTranscript = useCallback(async (handleTranscript, setVideoProcessed) => {
    const { transcriptData } = useStore.getState();
    
    if (!transcriptData) {
      setUploadError('No transcript data available. Please upload a file first.');
      return;
    }

    try {
      // Pass the original transcript data to be reprocessed with current settings
      handleTranscript(transcriptData);
      setVideoProcessed(true);
    } catch (error) {
      setUploadError(error.message);
      console.error("Transcript generation error:", error);
    }
  }, [handleTranscript, setUploadError]);

  return {
    isUploading,
    uploadError,
    handleFileUpload,
    generateTranscript,
    hasTranscriptData: useStore(state => state.hasTranscriptData),
  };
}
