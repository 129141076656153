import { useCallback } from 'react';
import { preventOverlap } from '../../utils/TranscriptUtils';
import useStore from '../../store';

export function useDragAndDrop(transcriptBlocks, setTranscriptBlocks) {
  const setCurrentBlockIndex = useStore(state => state.setCurrentBlockIndex);
  const setSelectedBlockIndex = useStore(state => state.setSelectedBlockIndex);
  
  const handleDragEnd = useCallback((result) => {
    if (!result.destination) return;

    // Store the ID of the block being moved
    const movedBlockId = transcriptBlocks[result.source.index].id;
    
    // Reorder the blocks
    const newBlocks = Array.from(transcriptBlocks);
    const [reorderedItem] = newBlocks.splice(result.source.index, 1);
    newBlocks.splice(result.destination.index, 0, reorderedItem);

    // Apply overlap prevention
    const updatedBlocks = preventOverlap(newBlocks);
    
    // Find the new index of our moved block by its ID
    const newIndexOfMovedBlock = updatedBlocks.findIndex(block => block.id === movedBlockId);
    
    // Update the blocks array
    setTranscriptBlocks(updatedBlocks);
    
    // Update selection to follow the moved block
    setCurrentBlockIndex(newIndexOfMovedBlock);
    setSelectedBlockIndex(newIndexOfMovedBlock);
    
  }, [transcriptBlocks, setTranscriptBlocks, setCurrentBlockIndex, setSelectedBlockIndex]);

  return { handleDragEnd };
}

export default useDragAndDrop;