import React from 'react';
import { Spinner } from "@nextui-org/react";

export const LoadingSpinner = () => {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <div className="flex flex-col items-center">
        <Spinner 
          size="lg" 
          color="secondary"
          className="mb-6"
        />
        
        <h3 className="text-base font-mono text-default-500">
          Processing your media file...
        </h3>
      </div>
    </div>
  );
}; 