import { useCallback, useEffect } from 'react';
import useStore from '../../store';
import { useVideoSeeking } from '../../hooks';

export const useWordNavigation = () => {
  const {
    transcriptBlocks,
    currentBlockIndex,
    setCurrentBlockIndex,
    setSelectedBlockIndex,
    currentTime,
    setCurrentTime,
    getCurrentWordIndex,
    setSelectedWords,
    selectionAnchorIndex,
    setSelectionAnchorIndex,
    isShiftSelecting,
    setIsShiftSelecting,
    selectedWordsInBlock,
    isSearchInputFocused,
    isTextInputFocused
  } = useStore();
  const { handleSeek } = useVideoSeeking();

  const handleWordNavigation = useCallback((direction, isShiftKey) => {
    console.log('handleWordNavigation called:', { 
      isTextInputFocused, 
      direction, 
      isShiftKey,
      activeElement: document.activeElement?.tagName
    });

    if (isTextInputFocused) {
      console.log('Preventing word navigation - text input focused');
      return;
    }

    if (transcriptBlocks.length === 0) {
      console.warn('No transcript blocks available for navigation');
      return;
    }

    const currentBlock = transcriptBlocks[currentBlockIndex];
    const currentWordIndex = getCurrentWordIndex();

    if (currentWordIndex === -1) {
      console.warn('No current word available for navigation');
      return;
    }

    let newWordIndex = currentWordIndex + direction;
    let newBlockIndex = currentBlockIndex;

    if (newWordIndex < 0 || newWordIndex >= currentBlock.words.length) {
      newBlockIndex = currentBlockIndex + direction;
      if (newBlockIndex < 0 || newBlockIndex >= transcriptBlocks.length) {
        console.warn('New block index out of bounds');
        return;
      }
      setCurrentBlockIndex(newBlockIndex);
      setSelectedBlockIndex(newBlockIndex);

      newWordIndex = direction === -1 ? transcriptBlocks[newBlockIndex].words.length - 1 : 0;
    }

    const newWord = transcriptBlocks[newBlockIndex].words[newWordIndex];
    const newTime = newWord.start + 0.001;

    setCurrentTime(newTime);
    handleSeek(newTime);

    if (isShiftKey) {
      if (!isShiftSelecting) {
        setIsShiftSelecting(true);
        setSelectionAnchorIndex(currentWordIndex);
      }

      const anchorIndex = selectionAnchorIndex !== null ? selectionAnchorIndex : currentWordIndex;
      const start = Math.min(anchorIndex, newWordIndex);
      const end = Math.max(anchorIndex, newWordIndex);
      const selectedIndices = Array.from({ length: end - start + 1 }, (_, i) => start + i);

      setSelectedWords(newBlockIndex, selectedIndices);
    } else {
      setIsShiftSelecting(false);
      setSelectionAnchorIndex(null);
      setSelectedWords(newBlockIndex, []);
    }

  }, [
    handleSeek,
    transcriptBlocks,
    currentBlockIndex,
    currentTime,
    getCurrentWordIndex,
    setCurrentBlockIndex,
    setCurrentTime,
    setSelectedBlockIndex,
    setSelectedWords,
    selectionAnchorIndex,
    setSelectionAnchorIndex,
    isShiftSelecting,
    setIsShiftSelecting,
    isTextInputFocused
  ]);

  const seekToFirstSelectedWord = useCallback(() => {
    const selectedWords = selectedWordsInBlock[currentBlockIndex];
    if (selectedWords && selectedWords.length > 0) {
      const firstSelectedWordIndex = selectedWords[0];
      const newWord = transcriptBlocks[currentBlockIndex].words[firstSelectedWordIndex];
      const newTime = newWord.start + 0.001;
      setCurrentTime(newTime);
      handleSeek(newTime);
    }
  }, [currentBlockIndex, selectedWordsInBlock, transcriptBlocks, setCurrentTime, handleSeek]);

  const handleShiftKeyUp = useCallback(() => {
    if (isShiftSelecting) {
      setIsShiftSelecting(false);
      seekToFirstSelectedWord();
    }
  }, [isShiftSelecting, setIsShiftSelecting, seekToFirstSelectedWord]);

  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.key === 'Shift') {
        handleShiftKeyUp();
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleShiftKeyUp]);

  return { handleWordNavigation };
};

export default useWordNavigation;
