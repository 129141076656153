import React from 'react';
import { Checkbox } from '@nextui-org/checkbox';
import { Input, Button, Slider, Card, CardBody } from "@nextui-org/react";
import useStore from '../../store';
import clsx from "clsx";

// Custom button styles using the highlight colors
const CustomButton = ({ color, ...props }) => {
  const buttonClasses = clsx(
    "transition-colors",
    color === "highlight" && "bg-yellow-100/70 hover:bg-yellow-100/80",
    color === "current" && "bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow",
    color === "reset" && "bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow",
  );

  return (
    <Button
      {...props}
      className={clsx(props.className, buttonClasses)}
    />
  );
};

export const TranscriptSettings = ({
  onGenerateTranscript,
  showRegenerateButton,
  onRegenerateTranscript,
  disabled,
  hasVideo,
  onLoadLocalProject,
  setHasGeneratedBlocks,
}) => {

  const {
    breakAtPunctuation = false,
    setBreakAtPunctuation,
    useMaxCharacters = false,
    setUseMaxCharacters,
    maxCharPerSection,
    setMaxCharPerSection,
    breakOnSpeakerChange,
    setBreakOnSpeakerChange,
    rejectLongSilences,
    setRejectLongSilences,
    longSilenceThreshold = 0.2,
    setLongSilenceThreshold,
    breakOnLongSilences,
    setBreakOnLongSilences,
    rejectBlocksContaining,
    setRejectBlocksContaining,
    rejectBlocksCondition,
    setRejectBlocksCondition,
    rejectBlocksWord,
    setRejectBlocksWord,
    setIsTextInputFocused,
  } = useStore();

  const updateTranscript = async () => {
    try {
      const localProject = await onLoadLocalProject();
      console.log('Loaded state:', localProject);
      if (localProject) {
        setHasGeneratedBlocks(true);
      }
    } catch (error) {
      console.error('Error loading project:', error);
      throw error;
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="flex flex-col gap-4">
          <div>
            {!showRegenerateButton ? (
              <div>
                <CustomButton
                  color="current"
                  onClick={onGenerateTranscript}
                >
                  Generate Transcript
                </CustomButton>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <CustomButton
                  color="reset"
                  onClick={onRegenerateTranscript}
                  disabled={disabled}
                >
                  Reset Transcript
                </CustomButton>
                <p className="text-xs text-default-500 italic">
                  You can reset your transcript with new settings, but make sure to save and start a new project if you don't want to lose your existing work.
                </p>
              </div>
            )}
          </div>

          <div>
            <p className="text-small font-bold mb-2">Cut Blocks at...</p>

            <div className="space-y-4 pl-2">
              <div className="flex gap-6 items-center">
                <Checkbox
                  id="breakAtPunctuation"
                  aria-label="Break at punctuation"
                  isSelected={breakAtPunctuation}
                  onValueChange={setBreakAtPunctuation}
                  size="sm"
                >
                  <span className="text-small">Punctuation</span>
                </Checkbox>
              </div>

              <div className="flex gap-6 items-center">
                <Checkbox
                  id="useMaxCharacters"
                  aria-label="Use maximum characters per section"
                  isSelected={useMaxCharacters}
                  onValueChange={setUseMaxCharacters}
                  size="sm"
                >
                  <span className="text-small">Max Characters</span>
                </Checkbox>

                <div className={`flex-1 ${!useMaxCharacters ? 'opacity-50' : ''}`}>
                  <span className="text-xs text-default-600 mb-1 block">
                    Characters per section: {maxCharPerSection.toLocaleString()}
                  </span>
                  <Slider
                    size="sm"
                    step={maxCharPerSection < 50 ? 1 : 
                         maxCharPerSection < 200 ? 5 : 
                         maxCharPerSection < 1000 ? 25 : 100}
                    minValue={1}
                    maxValue={5000}
                    defaultValue={500}
                    value={maxCharPerSection}
                    onChange={value => setMaxCharPerSection(value)}
                    className="max-w-md"
                    disabled={!useMaxCharacters}
                    aria-label="Maximum characters per section"
                    marks={[
                      { value: 1, label: '1' },
                      { value: 2500, label: '2,500' },
                      { value: 5000, label: '5,000' }
                    ]}
                  />
                </div>
              </div>

              <div className="flex gap-6 items-center">
                <Checkbox
                  id="breakOnLongSilences"
                  aria-label="Break on silences"
                  isSelected={breakOnLongSilences}
                  onValueChange={setBreakOnLongSilences}
                  size="sm"
                >
                  <span className="text-small">Silences</span>
                </Checkbox>

                <div className={`flex-1 ${!breakOnLongSilences ? 'opacity-50' : ''}`}>
                  <span className="text-xs text-default-600 mb-1 block">
                    Silence Threshold: {longSilenceThreshold}s
                  </span>
                  <Slider
                    size="sm"
                    step={0.1}
                    minValue={0.1}
                    maxValue={60}
                    value={longSilenceThreshold}
                    onChange={value => setLongSilenceThreshold(value)}
                    className="max-w-md"
                    disabled={!breakOnLongSilences}
                    aria-label="Silence threshold"
                    marks={[
                      { value: 0.1, label: '0.1s' },
                      { value: 30, label: '30s' },
                      { value: 60, label: '60s' }
                    ]}
                  />
                </div>
              </div>

              <div className={`pl-6 ${!breakOnLongSilences ? 'opacity-50' : ''}`}>
                <Checkbox
                  id="rejectLongSilences"
                  aria-label="Reject silences"
                  isSelected={rejectLongSilences}
                  onValueChange={setRejectLongSilences}
                  isDisabled={!breakOnLongSilences}
                  size="sm"
                >
                  <span className="text-small">Reject Silences</span>
                </Checkbox>
              </div>

              <div className="flex gap-6 items-start">
                <Checkbox
                  id="rejectBlocksContaining"
                  aria-label="Reject blocks based on word content"
                  isSelected={rejectBlocksContaining}
                  onValueChange={setRejectBlocksContaining}
                  size="sm"
                >
                  <span className="text-small">Reject blocks that</span>
                </Checkbox>

                <div className={`flex-1 ${!rejectBlocksContaining ? 'opacity-50' : ''}`}>
                  <div className="flex flex-col gap-2">
                    <select
                      value={rejectBlocksCondition}
                      onChange={(e) => setRejectBlocksCondition(e.target.value)}
                      disabled={!rejectBlocksContaining}
                      className="px-2 py-1 rounded border border-default-200 w-fit"
                    >
                      <option value="contain">contain</option>
                      <option value="dont-contain">don't contain</option>
                    </select>

                    <Input
                      size="sm"
                      placeholder="word or phrase"
                      value={rejectBlocksWord}
                      onChange={(e) => setRejectBlocksWord(e.target.value)}
                      onFocus={() => setIsTextInputFocused(true)}
                      onBlur={() => setIsTextInputFocused(false)}
                      disabled={!rejectBlocksContaining}
                      className="max-w-[200px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};