import axios from '../../utils/axios';
import useStore from '../../store';

const login = async (username, password) => {
  try {
    console.log('AuthService: Attempting login...');
    const { data } = await axios.post('/auth/login', { username, password }, { withCredentials: true });
    console.log('AuthService: Login response:', data);
    
    if (data.token) {
      useStore.getState().setToken(data.token);
    }
    return data;
  } catch (error) {
    console.error('AuthService: Login error:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Login failed';
    throw new Error(errorMessage);
  }
};

const signup = async (username, password, verificationBypass) => {
  try {
    console.log('AuthService: Attempting signup...');
    const { data } = await axios.post('/auth/signup', { username, password, verificationBypass });
    console.log('AuthService: Signup response:', data);
    
    if (data.token) {
      useStore.getState().setToken(data.token);
    }
    return data;
  } catch (error) {
    console.error('AuthService: Signup error:', error);
    const errorMessage = error.response?.data?.error || 
                        error.response?.data?.message || 
                        error.message || 
                        'Signup failed';
    throw new Error(errorMessage);
  }
};

const refreshToken = async () => {
  try {
    const { data } = await axios.post('/auth/refresh-token', {}, { withCredentials: true });
    if (data.token) {
      useStore.getState().setToken(data.token);
    }
    return data;
  } catch (error) {
    console.error('AuthService: Refresh token error:', error);
    throw error;
  }
};

const logout = async () => {
  try {
    await axios.post('/auth/logout', {}, { withCredentials: true });
    useStore.getState().logout();
  } catch (error) {
    console.error('Logout error:', error);
    // Still logout locally even if server request fails
    useStore.getState().logout();
    throw error;
  }
};

const authService = { login, signup, logout, refreshToken };
export default authService;