import { API_ENDPOINTS } from '../../config';
import axios from '../../utils/axios';

const LOCAL_STORAGE_KEY = 'spookycut_project_state';

class ProjectStateService {
  static async listAllProjectsGroupedBySHA() {
    console.log('ProjectStateService :: listAllProjectsGroupedBySHA from backend:', `/project/listAll`);
    try {
      const response = await axios.get(`/project/listAll`);
      console.log('Response from /project/listAll:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error listing all projects:', error);
      throw error;
    }
  }

  static async listProjectsForSHA(videoSHA) {
    const url = `/project/${videoSHA}/list`;
    console.log('listProjectsForSHA from backend:', url);

    try {
      const response = await axios.get(url);
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      return response.data;
    } catch (error) {
      console.error('Error listing projects for SHA:', error);
      throw error;
    }
  }

  static async loadProject(videoSHA, projectFileName) {
    const fileName = projectFileName.endsWith('.json') 
      ? projectFileName 
      : `${projectFileName}.json`;

    const url = `/project/${videoSHA}/${fileName}`;
    console.log('ProjectStateService::loadProject() attempting to load from:', url);
    
    try {
      const response = await axios.get(url);
      console.log('Load project response:', response.data);
      return response.data;
    } catch (error) {
      console.error('ProjectStateService::loadProject()::Error loading project:', {
        url,
        error: error.message,
        status: error.response?.status,
        responseData: error.response?.data
      });
      throw error;
    }
  }

  static async saveToLocalStorage(projectState) {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(projectState));
      console.log('Saved project state to localStorage:', projectState);
      return projectState;
    } catch (error) {
      console.error('Error saving project state to localStorage:', error);
      throw error;
    }
  }

  static async save(projectState) {
    try {
      await ProjectStateService.saveToLocalStorage(projectState);
      return projectState;
    } catch (error) {
      console.error('Error saving project state:', error);
      throw error;
    }
  }

  static async loadFromLocalStorage() {
    try {
      const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (!savedState) {
        return null;
      }
      return JSON.parse(savedState);
    } catch (error) {
      console.error('Error loading project state from localStorage:', error);
      return null;
    }
  }

  static async load() {
    try {
      const localProjectState = await ProjectStateService.loadFromLocalStorage();
      if (!localProjectState) {
        return {};
      }
      return localProjectState;
    } catch (error) {
      console.error('Error loading project state:', error);
      return {};
    }
  }

  static async saveToBackend(videoSHA, projectFileName, projectState) {
    const fileName = projectFileName.endsWith('.json') 
      ? projectFileName 
      : `${projectFileName}.json`;

    console.log('Saving projectName:', fileName);
    console.log('Saving project state to backend:', `/project/${videoSHA}/save`);
    
    try {
      const response = await axios.put(`/project/${videoSHA}/save`, {
        ...projectState,
        projectFileName: fileName
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Saved project state to backend:', projectState);
      return response.data;
    } catch (error) {
      console.error('Error saving project state to backend:', error);
      throw error;
    }
  }

  static async loadFromBackend(videoSHA, projectFileName) {
    const fileName = projectFileName.endsWith('.json') 
      ? projectFileName 
      : `${projectFileName}.json`;

    const url = `/project/${videoSHA}/${fileName}`;
    console.log('Loading project state from backend:', url);
    
    try {
      const response = await axios.get(url);
      console.log('Loaded project state from backend:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error loading project state from backend:', {
        url,
        error: error.message,
        status: error.response?.status,
        responseData: error.response?.data
      });
      throw error;
    }
  }

  static getCurrentState() {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedState ? JSON.parse(savedState) : null;
  }

  static clearState() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  static async moveProjectToTrash(videoSHA, filename) {
    console.log('ProjectStateService::moveProjectToTrash()', { videoSHA, filename });
    const cleanFilename = filename.replace('.json', '');
    const url = `/project/${videoSHA}/${cleanFilename}/trash`;
    
    try {
      console.log('Making trash request to:', url);
      const response = await axios.post(
        url,
        {}, // empty body
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Trash response:', response.data);
      
      if (!response.data.success) {
        throw new Error(response.data.error || 'Failed to move project to trash');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error moving project to trash:', {
        url,
        error: {
          message: error.message,
          name: error.name,
          code: error.code,
          status: error.response?.status,
          statusText: error.response?.statusText,
          data: error.response?.data
        }
      });
      throw error;
    }
  }

  static async getManifest(videoSHA) {
    try {
      const response = await axios.get(`/manifest/${videoSHA}`);
      return response.data;
    } catch (error) {
      console.warn('Error loading manifest:', error);
      return null;
    }
  }
}

export default ProjectStateService;