import { create } from 'zustand';

export const useSequentialPlaybackStore = create((set, get) => ({
  isSequentialWithNextBlock: (currentBlock, nextBlock) => {
    if (!currentBlock || !nextBlock) return false;
    
    const timeDiff = Math.abs(nextBlock.start - currentBlock.end);
    const sameRejectionState = currentBlock.isRejected === nextBlock.isRejected;
    
    return timeDiff < 0.1 && sameRejectionState;
  },

  handleBlockEnd: (
    currentBlock, 
    nextBlock,
    handleSeek,
    setCurrentBlockIndex,
    setSelectedBlockIndex,
    handlePlayPause,
    transcriptBlocks
  ) => {
    if (currentBlock.isRejected && !nextBlock) {
      handlePlayPause(false);
      return;
    }

    const canPlayNextBlock = currentBlock.isRejected || (nextBlock && !nextBlock.isRejected);
    
    if (!canPlayNextBlock) {
      const firstNonRejectedBlock = transcriptBlocks.find(block => !block.isRejected);
      if (firstNonRejectedBlock) {
        const firstBlockIndex = transcriptBlocks.indexOf(firstNonRejectedBlock);
        handleSeek(firstNonRejectedBlock.start);
        setCurrentBlockIndex(firstBlockIndex);
        setSelectedBlockIndex(firstBlockIndex);
        handlePlayPause(false);
      }
      return;
    }

    if (!nextBlock) return;

    const isSequential = get().isSequentialWithNextBlock(currentBlock, nextBlock);
    
    if (isSequential) {
      setCurrentBlockIndex();
      setSelectedBlockIndex();
    } else {
      handleSeek(nextBlock.start);
      setCurrentBlockIndex();
      setSelectedBlockIndex();
    }
  }
})); 