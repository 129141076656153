import { useCallback } from 'react';
import useStore from '../../store';

export function useWordAdjustment(handleSeek, setFlashingButton) {
  const { adjustFirstWord, adjustLastWord } = useStore();

  const handleAdjustFirstWord = useCallback((adjustment) => {
    adjustFirstWord(adjustment, handleSeek, setFlashingButton);
  }, [adjustFirstWord, handleSeek, setFlashingButton]);

  const handleAdjustLastWord = useCallback((adjustment) => {
    adjustLastWord(adjustment, handleSeek, setFlashingButton);
  }, [adjustLastWord, handleSeek, setFlashingButton]);

  return { handleAdjustFirstWord, handleAdjustLastWord };
}

export default useWordAdjustment;