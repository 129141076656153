import React, { useState, useEffect, useCallback } from 'react';
import useStore from '../../store';
import './ContextualToolbar.css';
import { Button, Tooltip } from '@nextui-org/react';
import useBlockHandlers from '../../hooks/useBlockHandlers';

// Simple Play Icon component
const PlayIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    className="h-3 w-3"
  >
    <path d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347c-.75.412-1.667-.13-1.667-.986V5.653Z" />
  </svg>
);

// Add these arrow icon components at the top with PlayIcon
const LeftArrowIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    className="h-3 w-3"
  >
    <path d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" />
  </svg>
);

const RightArrowIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    className="h-3 w-3"
  >
    <path d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" />
  </svg>
);

export const ContextualToolbar = ({
  selectedBlock,
  currentWordIndex,
  onFineSeek,
  videoPlayerRef
}) => {
  const { 
    transcriptBlocks, 
    setTranscriptBlocks, 
    isContextualToolbarVisible,
    setIsTextInputFocused
  } = useStore();
  const [flashingWord, setFlashingWord] = useState(null);
  const [flashingButton, setFlashingButton] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');
  const playCurrentWord = useStore(state => state.playCurrentWord);
  const { handleBlockSelection } = useBlockHandlers(onFineSeek);
  const [editingWordStart, setEditingWordStart] = useState(null);

  // Move useCallback before the visibility check
  const updateBlockTimings = useCallback((updatedBlocks) => {
    const finalBlocks = updatedBlocks.map(block => {
      if (!block.words.length) return block;
      
      // Update block start/end times based on its words
      const blockStart = Math.min(...block.words.map(w => w.start));
      const blockEnd = Math.max(...block.words.map(w => w.end));
      
      return {
        ...block,
        start: blockStart,
        end: blockEnd
      };
    });
    
    setTranscriptBlocks(finalBlocks);
  }, [setTranscriptBlocks]);

  // Only check visibility toggle, ignore word index
  if (!isContextualToolbarVisible) return null;

  const findWordAndNeighbors = () => {
    if (!transcriptBlocks || !selectedBlock || currentWordIndex === null) return null;
    
    if (!selectedBlock.words || 
        !Array.isArray(selectedBlock.words) || 
        currentWordIndex >= selectedBlock.words.length) {
      return null;
    }
    
    // Use reduce instead of flatMap for better compatibility
    const allWords = transcriptBlocks.reduce((acc, block) => {
      if (!block.words) return acc;
      return acc.concat(block.words);
    }, []).sort((a, b) => a.start - b.start);
    
    const currentWord = selectedBlock.words[currentWordIndex];
    
    if (!currentWord || typeof currentWord.start !== 'number' || typeof currentWord.end !== 'number') {
      return null;
    }
    
    const chronologicalIndex = allWords.findIndex(
      word => word.start === currentWord.start
    );
    
    if (chronologicalIndex === -1) return null;
    
    return {
      previousWords: allWords.slice(Math.max(0, chronologicalIndex - 2), chronologicalIndex),
      currentWord: currentWord,
      nextWords: allWords.slice(chronologicalIndex + 1, chronologicalIndex + 3),
      prevBoundary: chronologicalIndex > 0 ? allWords[chronologicalIndex - 1].end : null,
      nextBoundary: chronologicalIndex < allWords.length - 1 ? allWords[chronologicalIndex + 1].start : null
    };
  };

  const words = findWordAndNeighbors();
  
  // If we don't have valid word data, show an empty toolbar rather than hiding it completely
  if (!words) {
    return (
      <div className="contextual-toolbar">
        <div className="word-carousel">
          <div className="center-controls">
            <div className="current-word">
              
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleWordEdit = (newText) => {
    // If we're no longer on the word we started editing, cancel the edit
    if (editingWordStart !== words.currentWord.start) {
      console.log('handleWordEdit :: Canceling edit - word changed during edit');
      setIsEditing(false);
      setEditValue('');
      setEditingWordStart(null);
      return;
    }

    const { currentWord } = words;
    const trimmedText = newText.trim();
    
    // If empty text, remove the word entirely
    if (!trimmedText) {
      const updatedBlocks = transcriptBlocks.map(block => ({
        ...block,
        words: block.words.filter(word => word.start !== currentWord.start),
        content: block.words
          .filter(word => word.start !== currentWord.start)
          .map(word => word.word)
          .join(' ')
      }));
      setTranscriptBlocks(updatedBlocks);
      setIsEditing(false);
      setEditValue('');
      setEditingWordStart(null);
      return;
    }

    const wordParts = trimmedText.split(/\s+/);
    
    if (wordParts.length === 1) {
      // Check if this is converting from silence to word
      const isConvertingFromSilence = currentWord.type === 'silence';
      const isNewSilence = newText.match(/^\[.*\]$/); // Matches anything in brackets
      
      // Simple word update
      const updatedBlocks = transcriptBlocks.map(block => ({
        ...block,
        words: block.words.map(word => 
          word.start === currentWord.start
            ? { 
                ...word, 
                word: newText,
                type: isNewSilence ? 'silence' : 'word'  // Update type based on content
              }
            : word
        ),
        content: block.words.map(word => 
          word.start === currentWord.start ? newText : word.word
        ).join(' ')
      }));
      setTranscriptBlocks(updatedBlocks);
    } else {
      // Split into multiple words - always treat as regular words
      const totalDuration = currentWord.end - currentWord.start;
      const wordDuration = totalDuration / wordParts.length;
      
      const newWords = wordParts.map((part, index) => ({
        word: part,
        type: 'word',  // New split words are always regular words
        start: currentWord.start + (index * wordDuration),
        end: currentWord.start + ((index + 1) * wordDuration),
      }));
      
      const updatedBlocks = transcriptBlocks.map(block => ({
        ...block,
        words: block.words.flatMap(word => 
          word.start === currentWord.start
            ? newWords
            : [word]
        ),
        content: block.words.flatMap(word => 
          word.start === currentWord.start
            ? newWords.map(w => w.word)
            : [word.word]
        ).join(' ')
      }));
      setTranscriptBlocks(updatedBlocks);
    }
  };

  const handleTimeAdjustment = (type, direction) => {
    const currentWords = findWordAndNeighbors();
    if (!currentWords) return;

    const { currentWord } = currentWords;
    const BASE_ADJUSTMENT = 0.050;
    const MIN_WORD_DURATION = 0.010;

    const tryAdjustment = (adjustmentValue) => {
        const adjustment = direction === 'decrease' ? -adjustmentValue : adjustmentValue;

        const flashButtonTemp = (buttonType) => {
            setFlashingButton(buttonType);
            setTimeout(() => setFlashingButton(null), 500);
        };

        if (type === 'end') {
            const nextWord = currentWords.nextWords[0];
            const newEnd = Number((currentWord.end + adjustment).toFixed(3));
            
            // Check if this adjustment would make the current word too short
            if ((newEnd - currentWord.start) < MIN_WORD_DURATION) {
                return false;
            }

            // Only check next word boundary when moving END point towards it (increasing)
            if (direction === 'increase' && nextWord && Math.abs(newEnd - nextWord.start) <= 0.075) {
                // Only snap if it won't make either word too short
                const snappedEnd = nextWord.start;
                
                if ((snappedEnd - currentWord.start) < MIN_WORD_DURATION) {
                    return false;
                }

                // Safe to snap
                const updatedBlocks = transcriptBlocks.map(block => ({
                    ...block,
                    words: block.words.map(word => {
                        if (word.start === currentWord.start) {
                            return { ...word, end: snappedEnd };
                        }
                        if (word.start === snappedEnd) {
                            return { ...word, start: snappedEnd };
                        }
                        return word;
                    })
                }));

                setTranscriptBlocks(updatedBlocks);
                onFineSeek(currentWord.start + 0.001);
                
                flashButtonTemp(direction === 'decrease' ? 'endLeft' : 'endRight');
                setFlashingWord('next');
                setTimeout(() => setFlashingWord(null), 500);
                return true;
            }

            // Only check next word minimum duration when moving END point towards it
            if (direction === 'increase' && nextWord && (nextWord.end - newEnd) < MIN_WORD_DURATION) {
                return false;
            }

            const updatedBlocks = transcriptBlocks.map(block => {
                const isLastWordInBlock = block.words[block.words.length - 1]?.start === currentWord.start;
                const updatedWords = block.words.map(word => 
                    word.start === currentWord.start
                        ? { ...word, end: newEnd }
                        : word
                );
                
                return {
                    ...block,
                    words: updatedWords,
                    end: isLastWordInBlock ? newEnd : block.end
                };
            });

            updateBlockTimings(updatedBlocks);
            onFineSeek(currentWord.start + 0.001);
            flashButtonTemp(direction === 'decrease' ? 'endLeft' : 'endRight');
            return true;
        }

        if (type === 'start') {
            const { prevBoundary } = currentWords;
            const previousWord = currentWords.previousWords[currentWords.previousWords.length - 1];
            const newStart = Math.max(0, Number((currentWord.start + adjustment).toFixed(3)));
            
            // Check if this adjustment would make the current word too short
            if ((currentWord.end - newStart) < MIN_WORD_DURATION) {
                return false;
            }

            // Only check previous word boundary when moving START point towards it (decreasing)
            if (direction === 'decrease' && prevBoundary !== null && Math.abs(newStart - prevBoundary) <= 0.075) {
                // Only snap if it won't make either word too short
                const snappedStart = prevBoundary;
                
                if ((currentWord.end - snappedStart) < MIN_WORD_DURATION) {
                    return false;
                }

                if (previousWord && (snappedStart - previousWord.start) < MIN_WORD_DURATION) {
                    return false;
                }

                // Safe to snap
                const updatedBlocks = transcriptBlocks.map(block => ({
                    ...block,
                    words: block.words.map(word => {
                        if (word.start === currentWord.start) {
                            return { ...word, start: snappedStart };
                        }
                        if (word.end === currentWord.start) {
                            return { ...word, end: snappedStart };
                        }
                        return word;
                    }),
                    start: block.words[0]?.start === currentWord.start ? snappedStart : block.start
                }));

                setTranscriptBlocks(updatedBlocks);
                onFineSeek(snappedStart + 0.001);
                
                flashButtonTemp(direction === 'decrease' ? 'startLeft' : 'startRight');
                setFlashingWord('prev');
                setTimeout(() => setFlashingWord(null), 500);
                return true;
            }

            // Only check previous word minimum duration when moving START point towards it
            if (direction === 'decrease' && previousWord && (newStart - previousWord.start) < MIN_WORD_DURATION) {
                return false;
            }
            
            const updatedBlocks = transcriptBlocks.map(block => {
                const isFirstWordInBlock = block.words[0]?.start === currentWord.start;
                const updatedWords = block.words.map(word => {
                    if (word.start === currentWord.start) {
                        return { ...word, start: newStart };
                    }
                    if (prevBoundary !== null && word.end === currentWord.start) {
                        return { ...word, end: newStart };
                    }
                    return word;
                });
                
                return {
                    ...block,
                    words: updatedWords,
                    start: isFirstWordInBlock ? newStart : block.start
                };
            });

            setTranscriptBlocks(updatedBlocks);
            onFineSeek(newStart + 0.001);
            flashButtonTemp(direction === 'decrease' ? 'startLeft' : 'startRight');
            return true;
        }

        return false;
    };

    // Try with base adjustment first
    if (!tryAdjustment(BASE_ADJUSTMENT)) {
        // If base adjustment fails, try with half
        if (!tryAdjustment(BASE_ADJUSTMENT / 2)) {
            // If both fail, flash the button to indicate failure
            const buttonType = type === 'start' 
                ? (direction === 'decrease' ? 'startLeft' : 'startRight')
                : (direction === 'decrease' ? 'endLeft' : 'endRight');
            setFlashingButton(buttonType);
            setTimeout(() => setFlashingButton(null), 500);
        }
    }
  };

  return (
    <div className="contextual-toolbar">
      <div className="word-carousel">
        <div className="surrounding-words left-words">
          {words.previousWords.map((word, idx) => (
            <div
              key={`prev-${idx}`}
              className="word-group"
            >
              <div
                onClick={() => onFineSeek(word.start)}
                className={`word-item ${flashingWord === 'prev' && idx === words.previousWords.length - 1 ? 'flashing' : ''}`}
                style={{ opacity: 0.2 + (idx * 0.2) }}
              >
                {word.type === 'silence' ? '[...]' : word.word}
              </div>
              {idx === words.previousWords.length - 1 && (
                <div 
                  className="adjacent-timecode"
                  style={{ opacity: 0.6 }}
                >
                  {word.end.toFixed(3)}s
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="center-controls">
          <div className="current-word">
            {isEditing ? (
              <input
                type="text"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                onFocus={() => setIsTextInputFocused(true)}
                onBlur={() => {
                  setIsTextInputFocused(false);
                  handleWordEdit(editValue);
                  setIsEditing(false);
                  setEditValue('');
                  setEditingWordStart(null);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setIsTextInputFocused(false);
                    handleWordEdit(editValue);
                    setIsEditing(false);
                    setEditValue('');
                    setEditingWordStart(null);
                  }
                  if (e.key === 'Escape') {
                    setIsTextInputFocused(false);
                    setIsEditing(false);
                    setEditValue('');
                    setEditingWordStart(null);
                  }
                }}
                autoFocus
                className="text-center w-full bg-transparent border-b border-current focus:outline-none"
              />
            ) : (
              <div 
                onClick={() => {
                  setIsEditing(true);
                  setEditValue(words.currentWord.word || '');
                  setEditingWordStart(words.currentWord.start);
                }}
                className="cursor-text"
              >
                {words.currentWord.type === 'silence' ? '[...]' : words.currentWord.word}
              </div>
            )}
          </div>
          <div className="controls-row">
            <div className="time-control-group">
              <div className="timecode">{words.currentWord.start.toFixed(3)}s</div>
              <div className="button-group">
                <Button
                  size="sm"
                  className={`time-button ${flashingButton === 'startLeft' ? 'button-flash' : ''}`}
                  onClick={() => handleTimeAdjustment('start', 'decrease')}
                >
                  <LeftArrowIcon />
                </Button>
                <Button
                  size="sm"
                  className={`time-button ${flashingButton === 'startRight' ? 'button-flash' : ''}`}
                  onClick={() => handleTimeAdjustment('start', 'increase')}
                >
                  <RightArrowIcon />
                </Button>
              </div>
            </div>
            
            <Button
              size="sm"
              className="play-button"
              onClick={() => {
                console.log('Play button clicked');
                playCurrentWord();
              }}
            >
              <PlayIcon />
            </Button>
            
            <div className="time-control-group">
              <div className="button-group">
                <Button
                  size="sm"
                  className={`time-button ${flashingButton === 'endLeft' ? 'button-flash' : ''}`}
                  onClick={() => handleTimeAdjustment('end', 'decrease')}
                >
                  <LeftArrowIcon />
                </Button>
                <Button
                  size="sm"
                  className={`time-button ${flashingButton === 'endRight' ? 'button-flash' : ''}`}
                  onClick={() => handleTimeAdjustment('end', 'increase')}
                >
                  <RightArrowIcon />
                </Button>
              </div>
              <div className="timecode">{words.currentWord.end.toFixed(3)}s</div>
            </div>
          </div>
        </div>

        <div className="surrounding-words right-words">
          {words.nextWords.map((word, idx) => (
            <div
              key={`next-${idx}`}
              className="word-group"
            >
              <div
                onClick={() => onFineSeek(word.start)}
                className={`word-item ${flashingWord === 'next' && idx === 0 ? 'flashing' : ''}`}
                style={{ opacity: 0.4 - (idx * 0.2) }}
              >
                {word.type === 'silence' ? '[...]' : word.word}
              </div>
              {idx === 0 && (
                <div 
                  className="adjacent-timecode"
                  style={{ opacity: 0.6 - (idx * 0.3) }}
                >
                  {word.start.toFixed(3)}s
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
