import React, { useState, useEffect } from 'react';
import { ProjectBrowser } from '../../components';
import { useProjects } from '../../hooks';

export const RetrieveFlow = () => {
  const { listAllProjectsGroupedBySHA, handleLoadProjectRemote } = useProjects();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await listAllProjectsGroupedBySHA();
        console.log("-------------", projects);
        setProjects(projects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [listAllProjectsGroupedBySHA]);
  return (
    <div className="h-full overflow-hidden center-section grid grid-cols-12 grid-rows-[auto,1fr] col-span-12 text-center justify-center items-center">
      <ProjectBrowser
        onLoadProject={handleLoadProjectRemote}
        onListProjects={listAllProjectsGroupedBySHA}
        showGroupedByVideo={true}
        variant="expanded"
      />
    </div>
  );
};

export default RetrieveFlow;