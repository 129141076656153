import React, { useRef, useEffect, useState, useCallback, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import useStore from '../../store';
import { useBlockHandlers, useDragAndDrop } from '../../hooks';
import { TranscriptBlock } from '..';
import { GrDrag as DraggableIcon } from "react-icons/gr";
import '../../index.css'; // Ensure this import is present to include the CSS

const TranscriptBlockComponent = (props) => {
  return <TranscriptBlock {...props} />;
};

const Row = React.forwardRef(({ index, data, onDragStart, onDragOver, onDrop, handleDragEnd: clearDragState, draggedIndex }, ref) => {
  const [isDraggable, setIsDraggable] = useState(false);
  const [showMoveButtons, setShowMoveButtons] = useState(false);
  const touchStartPosRef = useRef(null);
  const initialIndexRef = useRef(null);
  const localRef = useRef(null);

  const {
    transcriptBlocks,
    onSeek,
    handleBlockSelection,
    handleBlockRejection,
    currentBlockIndex,
    currentTime,
    selectedBlockIndex,
    selectedWords,
    setSelectedWordsInBlock,
    isPlaying,
    handleDragEnd,
  } = data;
  const block = transcriptBlocks[index];

  const setRefs = useCallback((element) => {
    localRef.current = element;
    if (typeof ref === 'function') {
      ref(element);
    } else if (ref) {
      ref.current = element;
    }
  }, [ref]);

  // Setup non-passive touch event listeners
  useEffect(() => {
    const element = localRef.current;
    if (!element) return;

    const touchMoveHandler = (e) => {
      if (!touchStartPosRef.current || !isDraggable) return;
      
      e.preventDefault();
      
      const touch = e.touches[0];
      const currentY = touch.clientY;
      const deltaY = currentY - touchStartPosRef.current;
      
      const rowHeight = 60;
      const movement = Math.round(deltaY / rowHeight);
      
      if (movement !== 0) {
        console.log('Touch move', { deltaY, movement });
        const newIndex = Math.max(0, Math.min(initialIndexRef.current + movement, transcriptBlocks.length - 1));
        onDragOver(e, newIndex);
      }
    };

    element.addEventListener('touchmove', touchMoveHandler, { passive: false });
    return () => element.removeEventListener('touchmove', touchMoveHandler);
  }, [isDraggable, transcriptBlocks.length, onDragOver]);

  // Keep your existing touch handlers but remove preventDefault from them
  const handleTouchStart = (e) => {
    if (!e.target.closest('.grip-area')) return;
    
    console.log('Touch start', { index });
    const touch = e.touches[0];
    touchStartPosRef.current = touch.clientY;
    initialIndexRef.current = index;
    setIsDraggable(true);

    if (navigator.vibrate) {
      navigator.vibrate(25);
    }

    onDragStart(e, index);
  };

  const handleTouchEnd = (e) => {
    if (!isDraggable) return;
    console.log('Touch end');

    const touch = e.changedTouches[0];
    const deltaY = touch.clientY - touchStartPosRef.current;
    const rowHeight = 60;
    const movement = Math.round(deltaY / rowHeight);
    const finalIndex = Math.max(0, Math.min(initialIndexRef.current + movement, transcriptBlocks.length - 1));
    
    onDrop(e, finalIndex);
    setIsDraggable(false);
    touchStartPosRef.current = null;
    initialIndexRef.current = null;
    clearDragState();
  };

  // Add mouseup handler to document to catch all mouse up events
  useEffect(() => {
    const handleMouseUp = () => {
      setIsDraggable(false);
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const handleMouseDown = (e) => {
    if (e.target.closest('.grip-area')) {
      setIsDraggable(true);
      e.currentTarget.setAttribute('draggable', 'true');
    }
  };

  const handleDragStartWrapper = (e) => {
    if (isDraggable) {
      e.dataTransfer.effectAllowed = 'move';
      onDragStart(e, index);
    } else {
      e.preventDefault();
    }
  };

  const handleDragEndWrapper = (e) => {
    setIsDraggable(false);
    e.currentTarget.removeAttribute('draggable');
    handleDragEnd(e);
  };

  // Only apply opacity when actually dragging AND mouse is still down
  const isDragging = draggedIndex === index && isDraggable;

  // Add a class to show where the item will be dropped
  const getDropIndicatorClass = () => {
    if (draggedIndex === null || draggedIndex === index) return '';
    return draggedIndex < index ? 'drop-target-below' : 'drop-target-above';
  };

  const handleGripClick = (e) => {
    if (!showMoveButtons) {
      e.stopPropagation();
      setShowMoveButtons(true);
    }
  };

  const handleMoveClick = (direction) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < transcriptBlocks.length) {
      handleDragEnd({
        source: { index },
        destination: { index: newIndex }
      });
      handleBlockSelection(newIndex, currentTime, false);
    }
    setShowMoveButtons(false);
  };

  // Click outside to reset
  useEffect(() => {
    const handleClickOutside = () => {
      setShowMoveButtons(false);
    };

    if (showMoveButtons) {
      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  }, [showMoveButtons]);

  return (
    <div
      ref={setRefs}
      data-block-index={index}
      className={`draggable-row flex group relative ${isDragging ? 'opacity-30' : ''}`}
      style={{ cursor: isDragging ? 'move' : 'default' }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onDragStart={handleDragStartWrapper}
      onDragOver={(e) => onDragOver(e, index)}
      onDrop={(e) => onDrop(e, index)}
      onDragEnd={handleDragEndWrapper}
    >
      {/* Invisible drop zone that extends above and below */}
      <div
        className="absolute inset-0 -top-4 -bottom-4"
        style={{ zIndex: 1 }}
      />

      {/* Actual content */}
      <div className="flex w-full relative" style={{ zIndex: 2 }}>
        <div
          className="grip-area cursor-move active:bg-gray-400/30 hover:bg-gray-400/30 flex items-center transition-all ease-in-out duration-200 rounded-sm"
          onClick={handleGripClick}
        >
          {showMoveButtons ? (
            <div className="flex flex-col w-5">
              <button
                className={`h-1/2 flex items-center justify-center hover:bg-gray-400/40 transition-colors ${index === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleMoveClick('up')}
                disabled={index === 0}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              </button>
              <button
                className={`h-1/2 flex items-center justify-center hover:bg-gray-400/40 transition-colors ${index === transcriptBlocks.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleMoveClick('down')}
                disabled={index === transcriptBlocks.length - 1}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
          ) : (
            <div className="grip-lines opacity-0 w-5 group-hover:opacity-100 group-active:opacity-100 transition-all ease-in-out duration-200">
              <DraggableIcon style={{ marginLeft: '2px' }} />
            </div>
          )}
        </div>
        <div className="text-content w-full">
          <TranscriptBlockComponent
            {...block}
            onSeek={onSeek}
            onClick={() => {
              if (isPlaying) return;
              handleBlockSelection(index, block.start);
            }}
            isCurrentBlock={index === currentBlockIndex}
            currentTime={currentTime}
            isRejected={block.isRejected}
            onReject={() => {
              if (isPlaying) return;
              handleBlockRejection(index);
            }}
            isSelected={index === selectedBlockIndex}
            blockIndex={index}
            selectedWords={index === selectedBlockIndex ? selectedWords : []}
            onSelectedWordsChange={setSelectedWordsInBlock}
            onBlockSelect={() => {
              if (isPlaying) return;
              handleBlockSelection(index, block.start);
            }}
          />
        </div>
      </div>
    </div>
  );
});

const ShadowRootWrapper = ({ children, placeholderRef }) => {
  const shadowRootRef = useRef(null);
  const [shadowRoot, setShadowRoot] = useState(null);

  useEffect(() => {
    if (shadowRootRef.current && !shadowRoot) {
      const shadow = shadowRootRef.current.attachShadow({ mode: 'open' });

      const styleElement = document.createElement('style');
      styleElement.textContent = `
        @import url('/src/index.css');
        .placeholder {
          width: 100%;
          height: 2px;
          background-color: rgb(var(--highlight));
          opacity: 0.8;
          border-radius: 9999px;
          margin: 4px 0;
        }
      `;
      shadow.appendChild(styleElement);

      const placeholder = document.createElement('div');
      placeholder.classList.add('placeholder');
      shadow.appendChild(placeholder);
      placeholderRef.current = placeholder;

      setShadowRoot(shadow);
    }
  }, [shadowRoot, placeholderRef]);

  return (
    <div ref={shadowRootRef}>
      {shadowRoot && ReactDOM.createPortal(children, shadowRoot)}
    </div>
  );
};

export const TranscriptOrderManager = ({ onSeek, currentTime, preventOverlap, isPreviewMode }) => {
  const [visibleBlocks, setVisibleBlocks] = useState(new Set());
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);
  const placeholderRef = useRef(null);
  const observerRef = useRef(null);
  const blockRefs = useRef({});
  const scrollContainerRef = useRef(null);
  const [dropTarget, setDropTarget] = useState(null);

  // Get transcriptBlocks and setter from store
  const transcriptBlocks = useStore(state => state.transcriptBlocks);
  const setTranscriptBlocks = useStore(state => state.setTranscriptBlocks);

  // Use the drag and drop hook
  const { handleDragEnd } = useDragAndDrop(transcriptBlocks, setTranscriptBlocks);

  // Get all necessary state from store
  const currentBlockIndex = useStore(state => state.currentBlockIndex);
  const selectedBlockIndex = useStore(state => state.selectedBlockIndex);
  const selectedWords = useStore(state => state.selectedWords);
  const setSelectedWordsInBlock = useStore(state => state.setSelectedWordsInBlock);
  const isPlaying = useStore(state => state.isPlaying);
  const searchTerm = useStore(state => state.searchQuery);

  // Handlers
  const handleBlockSelectionCallback = useCallback((index, start) => {
    // Implement block selection logic
  }, []);

  const handleBlockRejectionCallback = useCallback((index) => {
    // Implement block rejection logic
  }, []);

  const onDragStart = useCallback((e, index) => {
    setDraggedIndex(index);
  }, []);

  const onDragOver = useCallback((e, index) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseY = e.clientY - rect.top;
    
    // Calculate potential drop position
    const dropIndex = mouseY < rect.height / 2 ? index : index + 1;
    
    // Only set dropTargetIndex if it would result in an actual move
    // Don't allow dropping right before or after the dragged item
    if (dropIndex !== draggedIndex && dropIndex !== draggedIndex + 1) {
      setDropTargetIndex(dropIndex);
    } else {
      setDropTargetIndex(null);
    }
  }, [draggedIndex]);

  const onDrop = useCallback((e, index) => {
    if (draggedIndex !== null && dropTargetIndex !== null && dropTargetIndex !== draggedIndex && dropTargetIndex !== draggedIndex + 1) {
      // Adjust destination index if dropping below the dragged item
      const adjustedDestination = dropTargetIndex > draggedIndex ? dropTargetIndex - 1 : dropTargetIndex;
      
      handleDragEnd({ 
        source: { index: draggedIndex }, 
        destination: { index: adjustedDestination } 
      });
    }
    setDraggedIndex(null);
    setDropTargetIndex(null);
  }, [draggedIndex, dropTargetIndex, handleDragEnd]);

  const clearDragState = useCallback(() => {
    setDraggedIndex(null);
    setDropTargetIndex(null);
  }, []);

  // Setup intersection observer once
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        setVisibleBlocks(prev => {
          const next = new Set(prev);
          entries.forEach(entry => {
            const index = parseInt(entry.target.dataset.blockIndex);
            if (entry.isIntersecting) {
              next.add(index);
            } else {
              next.delete(index);
            }
          });
          return next;
        });
      },
      {
        root: scrollContainerRef.current,
        rootMargin: '150% 0px',
        threshold: 0
      }
    );

    observerRef.current = observer;

    // Observe any existing blocks
    const elements = document.querySelectorAll('[data-block-index]');
    elements.forEach(el => observer.observe(el));

    return () => {
      observer.disconnect();
      observerRef.current = null;
    };
  }, []);

  // Re-observe blocks when they change
  useEffect(() => {
    const observer = observerRef.current;
    if (!observer) return;

    // Clean up existing observations
    observer.disconnect();

    // Observe new blocks
    const elements = document.querySelectorAll('[data-block-index]');
    elements.forEach(el => observer.observe(el));

  }, [transcriptBlocks.length]);

  const lastOperationRef = useRef({
    blockCount: transcriptBlocks.length,
    rejectedCount: transcriptBlocks.filter(b => b.isRejected).length
  });

  // Split into two effects - one for background updates, one for immediate needs
  useEffect(() => {
    if (!scrollContainerRef.current || !observerRef.current) return;

    const currentRejectedCount = transcriptBlocks.filter(b => b.isRejected).length;
    const isStructuralChange = 
      transcriptBlocks.length !== lastOperationRef.current.blockCount ||
      currentRejectedCount !== lastOperationRef.current.rejectedCount;

    if (!isStructuralChange) return;

    // Update our reference
    lastOperationRef.current = {
      blockCount: transcriptBlocks.length,
      rejectedCount: currentRejectedCount
    };

    // Queue microtask for background refresh
    queueMicrotask(() => {
      const observer = observerRef.current;
      if (!observer) return;

      // Keep existing observations while adding new ones
      const elements = document.querySelectorAll('[data-block-index]');
      const currentlyVisible = new Set(visibleBlocks);

      elements.forEach(el => {
        const index = parseInt(el.dataset.blockIndex);
        const rect = el.getBoundingClientRect();
        const isInViewport = rect.top >= -window.innerHeight * 1.5 && 
                           rect.bottom <= window.innerHeight * 2.5;

        if (isInViewport) {
          currentlyVisible.add(index);
        }
      });

      setVisibleBlocks(currentlyVisible);
      
      // Reobserve all elements
      observer.disconnect();
      elements.forEach(el => observer.observe(el));
    });

  }, [transcriptBlocks, visibleBlocks]);

  // Modify the renderBlock to be more resilient
  const renderBlock = useCallback((block, index) => {
    const isVisible = visibleBlocks.has(index);
    const isNearCurrent = Math.abs(index - currentBlockIndex) < 10;
    const shouldRender = isVisible || isNearCurrent;

    return (
      <React.Fragment key={`block-${block.id}-${index}`}>
        {draggedIndex !== null && dropTargetIndex === index && (
          <div 
            key={`drop-indicator-top-${index}`}
            className="h-0.25 bg-sky-300/70 w-full my-1" 
          />
        )}
        
        <div 
          data-block-index={index} 
          className="block-container p-1"
          style={{ 
            height: shouldRender ? 'auto' : '60px',
            opacity: shouldRender ? 1 : 0
          }}
        >
          {shouldRender ? (
            <Row
              key={`row-${block.id}`}
              index={index}
              draggedIndex={draggedIndex}
              data={{
                transcriptBlocks,
                onSeek,
                handleBlockSelection: handleBlockSelectionCallback,
                handleBlockRejection: handleBlockRejectionCallback,
                currentBlockIndex,
                currentTime,
                selectedBlockIndex,
                selectedWords,
                setSelectedWordsInBlock,
                isPlaying,
                handleDragEnd,
              }}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              handleDragEnd={clearDragState}
            />
          ) : (
            <div key={`placeholder-${index}`} className="placeholder-block" />
          )}
        </div>
        
        {draggedIndex !== null && dropTargetIndex === index + 1 && (
          <div className="h-0.5 bg-sky-300/70 w-full my-1" />
        )}
      </React.Fragment>
    );
  }, [
    visibleBlocks,
    currentBlockIndex,
    draggedIndex,
    dropTargetIndex,
    transcriptBlocks,
    onSeek,
    handleBlockSelectionCallback,
    handleBlockRejectionCallback,
    currentTime,
    selectedBlockIndex,
    selectedWords,
    setSelectedWordsInBlock,
    isPlaying,
    handleDragEnd,
    onDragStart,
    onDragOver,
    onDrop,
    clearDragState
  ]);

  // Update observer options
  useEffect(() => {
    if (!scrollContainerRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        setVisibleBlocks(prev => {
          const next = new Set(prev);
          entries.forEach(entry => {
            const index = parseInt(entry.target.dataset.blockIndex);
            if (entry.isIntersecting) {
              next.add(index);
            } else if (!entry.isIntersecting && Math.abs(index - currentBlockIndex) > 10) {
              next.delete(index); // Only remove if far from current
            }
          });
          return next;
        });
      },
      {
        root: scrollContainerRef.current,
        rootMargin: '150% 0px',
        threshold: 0
      }
    );

    observerRef.current = observer;
    // ... rest of observer setup
  }, [currentBlockIndex]);

  return (
    <div
      ref={scrollContainerRef}
      className="transcript-manager h-full overflow-y-auto relative"
    >
      <div className="relative">
        <div className="flex flex-col gap-0">
          {transcriptBlocks.map(renderBlock)}
        </div>
      </div>
    </div>
  );
};