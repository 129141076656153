import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import useStore from '../../store';

import { Card, CardBody, Button, Tabs, Tab } from "@nextui-org/react";
import { preventOverlap } from '../../utils/TranscriptUtils';

import { 
  ProjectBrowser, 
  ContextualToolbar, 
  FileUploadSection, 
  VideoPlayer, 
  TranscriptSettings, 
  TranscriptOrderManager, 
  OutputSection, 
  ActionBar, 
  FindBarContainer, 
  TranslationSettings 
} from '../../components';
import { useProjects, useVideoSeeking, useTranscriptBlocks, useTranscriptProcessor, useFileUpload, useDragAndDrop, useKeyPress, useBlockNavigation, useWordAdjustment, useBlockHandlers } from '../../hooks';

import '../../App.css';
import { LoadingSpinner } from '../LoadingSpinner/index';
import JSONUpdateService from '../../services/JSONUpdateService';

// Add this style either inline or in your CSS file
const videoFileNameStyle = {
  fontSize: '0.875rem',
  fontStyle: 'italic',
  color: '#666',
  whiteSpace: 'normal',
  overflowWrap: 'anywhere',
  wordBreak: 'keep-all',
  maxWidth: '100%',
  padding: '0 8px',
  display: 'block',
  wordSpacing: '0.1px',
  textWrap: 'balance'
};

// Add this style for the duration display
const durationStyle = {
  fontSize: '0.875rem',
  padding: '0 8px',
  marginTop: '4px'
};

export function Editor() {
  // Video seeking hooks
  const {
    currentTime,
    isPlaying,
    videoPlayerRef,
    handleSeek,
    handleVideoPlayPause,
    handleTimeUpdate,
    handleFineSeek,
    handleStoppingOnRejectedBlock,
  } = useVideoSeeking();

  // Store state
  const {
    currentBlockIndex,
    selectedBlockIndex,
    breakAtSentence, setBreakAtSentence,
    maxCharPerSection, setMaxCharPerSection,
    breakOnSpeakerChange, setBreakOnSpeakerChange,
    videoFile, setVideoFile,
    videoFileName, setVideoFileName,
    isSeeking,
    flashingButton, setFlashingButton,
    videoProcessed, setVideoProcessed,
    rejectLongSilences, setRejectLongSilences,
    longSilenceThreshold, setLongSilenceThreshold,
    breakOnLongSilences, setBreakOnLongSilences,
    projectName, setProjectName,
    projectFileName, setProjectFileName,
    setIsTextInputFocused,
    rejectBlocksContaining, setRejectBlocksContaining,
    rejectBlocksCondition, setRejectBlocksCondition,
    rejectBlocksWord, setRejectBlocksWord,
  } = useStore();

  const { transcriptBlocks, setTranscriptBlocks } = useStore();
  const apiUrl = '/api';

  // Initialize transcript processor first
  const handleTranscript = useTranscriptProcessor();

  // Then initialize file upload with transcript processor
  const {
    isUploading,
    uploadError,
    handleFileUpload,
    generateTranscript,
    hasTranscriptData
  } = useFileUpload(
    apiUrl,
    handleTranscript,
    setVideoFile,
    setVideoProcessed,
    setVideoFileName
  );

  const { handleLoadLocalProject, listAllProjectsGroupedBySHA, handleLoadProjectRemote, listProjectsForSHA } = useProjects();

  // Add an effect to update filename when loading projects
  useEffect(() => {
    if (videoFile instanceof File) {
      setVideoFileName(videoFile.name);
    } else if (videoFile && typeof videoFile === 'string') {
      // Extract filename from URL or path
      const fileName = videoFile.split('/').pop();
      setVideoFileName(fileName);
    }
  }, [videoFile, setVideoFileName]);

  // Block and word handling hooks
  const { handleRejectBlock } = useTranscriptBlocks(handleSeek);
  const { handleBlockNavigation, handleBlockEnd } = useBlockNavigation(handleSeek, handleVideoPlayPause);
  const { handleAdjustFirstWord, handleAdjustLastWord } = useWordAdjustment(handleSeek, setFlashingButton);
  const { handleDragEnd } = useDragAndDrop(transcriptBlocks, setTranscriptBlocks);
  const { getCurrentWordIndex } = useBlockHandlers(handleSeek, currentTime);

  // Key press handling
  useKeyPress(handleBlockNavigation, handleVideoPlayPause, isPlaying, handleSeek);

  // Add state for tracking if blocks have been generated
  const [hasGeneratedBlocks, setHasGeneratedBlocks] = useState(false);

  // Callbacks
  const handleGenerateTranscript = useCallback(() => {
    console.log('Generating transcript:', { hasTranscriptData });
    if (hasTranscriptData) {
      generateTranscript(handleTranscript, setVideoProcessed);
      console.log('Generated transcript blocks');
      setHasGeneratedBlocks(true);
    }
  }, [generateTranscript, handleTranscript, setVideoProcessed, hasTranscriptData]);

  const handleRegenerateTranscript = useCallback(() => {
    handleGenerateTranscript();
  }, [handleGenerateTranscript]);

  const isContextualToolbarVisible = useStore(state => state.isContextualToolbarVisible);
  const isFindBarVisible = useStore(state => state.isFindBarVisible);

  useEffect(() => {
    if (transcriptBlocks.length > 0) {
      setHasGeneratedBlocks(true);
    }
  }, [transcriptBlocks]);

  const [activeTab, setActiveTab] = useState('layout');

  const tabsContainerRef = useRef(null);

  useEffect(() => {
    const updateTabsHeight = () => {
      if (tabsContainerRef.current) {
        const windowHeight = window.innerHeight;
        const tabsTop = tabsContainerRef.current.getBoundingClientRect().top;
        const availableHeight = windowHeight - tabsTop;
        tabsContainerRef.current.style.height = `${availableHeight}px`;
      }
    };

    updateTabsHeight();
    window.addEventListener('resize', updateTabsHeight);
    return () => window.removeEventListener('resize', updateTabsHeight);
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // No need to do anything special for language tab
  };

  // Add this useEffect in your Editor component to handle scaling
  useEffect(() => {
    const filename = document.querySelector('.video-filename');
    if (filename) {
      const parent = filename.parentElement;
      const scale = parent.offsetWidth / filename.offsetWidth;
      if (scale < 1) {
        filename.style.setProperty('--scale', scale);
      }
    }
  }, [videoFileName]); // Re-run when filename changes

  const newDuration = useMemo(() => 
    JSONUpdateService.formatDuration(JSONUpdateService.calculateDuration(transcriptBlocks)),
    [transcriptBlocks]
  );

  // Replace hyphens with non-breaking hyphens
  const formattedFileName = videoFileName?.replace(/-/g, '‑');

  return (
    <div className="relative overflow-hidden col-span-12">
      {/* Main content area - adjusted padding and height calculations */}
      <main className="h-full grid grid-rows-[auto,1fr] grid-cols-12 overflow-hidden ">
        <div className="flex flex-col z-50 col-span-12">
          {/* Action bar */}
          <div className="bg-background/80 border-b border-default-200 p-1">
            <ActionBar onFineSeek={handleFineSeek} />
          </div>

          {/* Find bar */}
          {isFindBarVisible && (
            <div className="bg-background/80 border-b border-default-200">
              <FindBarContainer isVisible={true} onSeek={handleSeek} />
            </div>
          )}

          {/* Contextual toolbar */}
          <div className="toolbar-area backdrop-blur-lg backdrop-saturate-150 bg-background/40 flex justify-end border-b border-default-200">
            {!isUploading && transcriptBlocks && transcriptBlocks.length === 0 ? (
              <>
              </>
            ) : (
              <ContextualToolbar
                selectedBlock={selectedBlockIndex !== null ? transcriptBlocks[selectedBlockIndex] : null}
                onReject={() => selectedBlockIndex !== null && handleRejectBlock(selectedBlockIndex)}
                onAdjustFirstWord={handleAdjustFirstWord}
                onAdjustLastWord={handleAdjustLastWord}
                flashingButton={flashingButton}
                videoProcessed={videoProcessed}
                currentTime={currentTime}
                onFineSeek={handleFineSeek}
                currentBlockIndex={currentBlockIndex}
                currentWordIndex={getCurrentWordIndex()}
              />
            )}
          </div>
        </div>
        {videoFile && (
          <div className="col-span-12 grid grid-cols-12 overflow-hidden">
            <div className="h-full overflow-hidden left-section col-span-3 flex flex-col">
              {videoFile && (
                <Card className="mb-2 flex-none">
                  <CardBody>
                    <VideoPlayer
                      videoPlayerRef={videoPlayerRef}
                      src={videoFile}
                      onSeek={handleSeek}
                      onPlayPause={handleVideoPlayPause}
                      onTimeUpdate={handleTimeUpdate}
                      onBlockEnd={handleBlockEnd}
                      currentBlock={transcriptBlocks[currentBlockIndex]}
                      isPlaying={isPlaying}
                      isSeeking={isSeeking}
                      handleStoppingOnRejectedBlock={handleStoppingOnRejectedBlock}
                    />
                    <div className="flex items-center mt-2">
                      <FileUploadSection
                        onFileUpload={handleFileUpload}
                        isUploading={isUploading}
                        uploadError={uploadError}
                        apiUrl={apiUrl}
                        compact={true}
                        hide={hasGeneratedBlocks}
                      />
                      {videoFileName && (
                        <div className="flex-1 min-w-0">
                          <div style={videoFileNameStyle}>
                            {formattedFileName}
                          </div>
                          <div style={durationStyle}>
                            New Duration: {newDuration}
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </div>
            <div className="h-full overflow-hidden center-section col-span-6 overflow-y-auto px-4">
              {(isUploading || !hasTranscriptData) && videoFile ? (
                <LoadingSpinner />
              ) : !isUploading && videoFile && transcriptBlocks.length === 0 ? (
                <div className="h-full w-full">
                  <div className="relative w-full pl-4 pt-8">
                    <div className="flex items-center gap-4">
                      <div className="p-8">
                        <h2 className="text-2xl font-mono mb-3">
                          Choose how you'd like your transcript sliced up and click Generate.
                        </h2>
                        <h3 className="text-base font-mono text-default-500">
                          For example, for a long transcript you may want to use "Reject All" and only pick out your favorite parts.
                        </h3>
                      </div>
                      <div className="text-default-400">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <TranscriptOrderManager
                  onDragEnd={handleDragEnd}
                  onSeek={handleSeek}
                  videoPlayerRef={videoPlayerRef}
                  currentTime={currentTime}
                  preventOverlap={preventOverlap}
                  onBlockNavigation={handleBlockNavigation}
                />
              )}
            </div>
            <div className="h-full overflow-hidden right-section col-span-3">
              {videoFile && (
                <div ref={tabsContainerRef} className="flex flex-col h-full overflow-hidden">
                  <Tabs 
                    aria-label="Editor tabs"
                    className="bg-transparent p-2 rounded-xl"
                    classNames={{
                      tabList: "gap-4 w-full relative rounded-lg p-2 bg-default-50 dark:bg-default-100/50 border border-default-100 shadow-[0_0_0_1px_rgba(0,0,0,0.03)]",
                      cursor: "bg-white dark:bg-default shadow-medium",
                      tab: "max-w-fit px-4 h-10 data-[selected=true]:text-default-foreground",
                      tabContent: "group-data-[selected=true]:font-medium",
                      panel: "pt-2"
                    }}
                    variant="solid"
                  >
                    <Tab
                      key="layout"
                      title={
                        <div className="flex items-center space-x-2">
                          <span>Layout</span>
                        </div>
                      }
                    >
                      <div className="p-2">
                        <TranscriptSettings
                          onLoadLocalProject={handleLoadLocalProject}
                          onGenerateTranscript={handleGenerateTranscript}
                          setHasGeneratedBlocks={setHasGeneratedBlocks}
                          showRegenerateButton={hasGeneratedBlocks}
                          onRegenerateTranscript={handleRegenerateTranscript}
                          hasVideo={Boolean(videoFile)}
                          breakAtSentence={breakAtSentence}
                          setBreakAtSentence={setBreakAtSentence}
                          maxCharPerSection={maxCharPerSection}
                          setMaxCharPerSection={setMaxCharPerSection}
                          breakOnSpeakerChange={breakOnSpeakerChange}
                          setBreakOnSpeakerChange={setBreakOnSpeakerChange}
                          rejectLongSilences={rejectLongSilences}
                          setRejectLongSilences={setRejectLongSilences}
                          longSilenceThreshold={longSilenceThreshold}
                          setLongSilenceThreshold={setLongSilenceThreshold}
                          breakOnLongSilences={breakOnLongSilences}
                          setBreakOnLongSilences={setBreakOnLongSilences}
                          rejectBlocksContaining={rejectBlocksContaining}
                          setRejectBlocksContaining={setRejectBlocksContaining}
                          rejectBlocksCondition={rejectBlocksCondition}
                          setRejectBlocksCondition={setRejectBlocksCondition}
                          rejectBlocksWord={rejectBlocksWord}
                          setRejectBlocksWord={setRejectBlocksWord}
                        />
                      </div>
                    </Tab>
                    
                    <Tab
                      key="cuts"
                      title={
                        <div className="flex items-center space-x-2">
                          <span>Cuts</span>
                        </div>
                      }
                    >
                      <div className="p-2">
                        <div className="flex flex-col gap-4">
                          <ProjectBrowser
                            onLoadProject={handleLoadProjectRemote}
                            filterBySHA={useStore.getState().videoSHA}
                            hideInputs={true}
                          />
                        </div>
                      </div>
                    </Tab>

                    <Tab
                      key="output"
                      title={
                        <div className="flex items-center space-x-2">
                          <span>Output</span>
                        </div>
                      }
                    >
                      <div className="p-2">
                        <OutputSection />
                      </div>
                    </Tab>

                    <Tab
                      key="language"
                      title={
                        <div className="flex items-center space-x-2">
                          <span>Language</span>
                        </div>
                      }
                    >
                      <div className="p-2">
                        <TranslationSettings 
                          handleTranscript={handleTranscript}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              )}
            </div>
          </div>)}
      </main>
    </div>
  );
}