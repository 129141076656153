const createAuthStore = (set) => ({
  token: localStorage.getItem('authToken') || null,
  setToken: (newToken) => {
    set({ token: newToken });
    if (newToken) {
      localStorage.setItem('authToken', newToken);
    } else {
      localStorage.removeItem('authToken');
    }
  },
  logout: () => {
    set({ token: null });
    localStorage.removeItem('authToken');
  },
});

export default createAuthStore;
