import React, { useState } from 'react';
import authService from '../../services/AuthService';
import { Button } from '@nextui-org/react';
import useStore from '../../store';

export const Login = ({ onSuccess }) => {
  const setToken = useStore((state) => state.setToken);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      console.log('Attempting login with:', { username });
      const response = await authService.login(username, password);
      console.log('Login response:', response);
      
      if (response.token) {
        setToken(response.token);
        onSuccess?.();
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleLogin} className="flex flex-col border rounded-2xl gap-2 border-default p-2">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-2xl" role="alert">
          <p className="text-sm">{error}</p>
        </div>
      )}

      <input
        type="text"
        placeholder="Email"
        className="p-2 px-4 rounded-2xl"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={loading}
        required
        autoComplete="username"
      />
      <input
        type="password"
        placeholder="Password"
        className="p-2 px-4 rounded-2xl"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
        required
        autoComplete="current-password"
      />
      <Button 
        type="submit" 
        size="lg"
        className="bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow"
        disabled={loading}
      >
        {loading ? 'Logging in...' : 'Login'}
      </Button>
    </form>
  );
};

export default Login;
