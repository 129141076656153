const ProjectValidationService = {
  validate: async (projectState, videoSHA) => {
    if (!projectState.videoSHA ||  typeof projectState.originalTranscript !== 'string') {
      return false;
    }

    try {
      const currentVideoSHA = videoSHA;
      return currentVideoSHA === projectState.videoSHA;
    } catch (error) {
      console.error('Error validating project state:', error);
      return false;
    }
  },
};

export default ProjectValidationService;
