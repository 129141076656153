import { Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { shortcutGroups } from './shortcutData';

export const KeyboardShortcutsModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') onClose();
    };
    
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
      return () => window.removeEventListener('keydown', handleEsc);
    }
  }, [isOpen, onClose]);

  const modalContent = (
    <Transition show={isOpen} as={Fragment}>
      <div className="fixed inset-0 isolate z-[99999] overflow-y-auto">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div 
            className="fixed inset-0 bg-black/50 transition-opacity" 
            onClick={onClose}
            style={{ zIndex: 8999 }}
          />
        </Transition.Child>

        <div 
          className="relative flex min-h-full items-center justify-center p-4"
          style={{ zIndex: 9001 }}
        >
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full transform transition-all">
            <button
              onClick={onClose}
              className="absolute -top-2 -right-2 p-1 bg-white dark:bg-gray-700 rounded-full shadow 
                       hover:bg-gray-100 dark:hover:bg-gray-600 
                       text-gray-700 dark:text-gray-200"
            >
              <span className="sr-only">Close</span>
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            <div className="p-6 dark:text-gray-100">
              <h2 className="text-lg font-semibold mb-4 dark:text-gray-200">
                Keyboard Shortcuts
              </h2>
              
              {Object.entries(shortcutGroups).map(([groupKey, group]) => (
                <div key={groupKey} className="mb-6 last:mb-0">
                  <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-3">
                    {group.title}
                  </h3>
                  <div className="space-y-2">
                    {group.shortcuts.map(({ keys, description }) => (
                      <div key={keys.join('')} className="flex items-center">
                        <div className="flex space-x-1 min-w-[120px]">
                          {keys.map(key => (
                            <kbd 
                              key={key}
                              className="px-2.5 py-1.5 text-xs font-semibold 
                                       text-gray-700 dark:text-gray-300
                                       bg-gray-100 dark:bg-gray-700 
                                       border border-gray-200 dark:border-gray-600 
                                       rounded-md shadow-sm"
                            >
                              {key}
                            </kbd>
                          ))}
                        </div>
                        <span className="text-sm text-gray-600 dark:text-gray-400 ml-4">
                          {description}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );

  return createPortal(modalContent, document.body);
};
