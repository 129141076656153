import { ProjectStateService, ProjectValidationService } from '../services';

const useProjectStore = (set, get) => ({
  projectState: null,
  isDirty: false,
  lastSaved: null,
  projectName: '',
  projectFileName: '',
  sourceLanguage: null,
  
  setProjectFileName: (name) => set({ projectFileName: name }),
  setProjectName: (name) => set({ projectName: name }),
  setProjectState: (state) => set({ projectState: state }),
  setIsDirty: (dirty) => set({ isDirty: dirty }),

  saveProject: async (isNewProject = false, newProjectName = undefined) => {
    try {
      console.log('Starting save process...', { isNewProject, newProjectName });
      const state = {
        projectFileName: isNewProject 
          ? `${Date.now()}.json` 
          : (get().projectFileName || `${Date.now()}.json`),
        projectName: newProjectName || get().projectName || 'Untitled Project',
        videoSHA: get().videoSHA || 'placeholder-sha',
        videoFileName: get().videoFileName,
        originalTranscript: get().originalTranscript || '',
        sourceLanguage: get().sourceLanguage,
        decisions: {
          blocks: get().transcriptBlocks || [],
          settings: {
            breakAtPunctuation: get().breakAtPunctuation || false,
            maxCharPerSection: get().maxCharPerSection || 500,
            breakOnSpeakerChange: get().breakOnSpeakerChange || false,
            rejectLongSilences: get().rejectLongSilences || false,
            longSilenceThreshold: get().longSilenceThreshold || 1.0,
          }
        },
        metadata: {
          lastModified: new Date().toISOString(),
          version: '1.0.0'
        }
      };

      // Validate required fields
      if (!state.videoSHA) {
        throw new Error('videoSHA is required for saving');
      }

      console.log('About to save state:', state);
      
      // First save locally
      await ProjectStateService.save(state);
      
      // Then save to backend
      await ProjectStateService.saveToBackend(
        state.videoSHA,
        state.projectFileName,
        state
      );
      
      console.log('Save completed');

      set({
        isDirty: false,
        lastSaved: new Date(),
        projectState: state,
        projectFileName: state.projectFileName,
        projectName: state.projectName
      });

      // Dispatch event after successful save
      console.log('Save completed in store, dispatching projectSaved event');
      window.dispatchEvent(new CustomEvent('projectSaved'));

      return state;
    } catch (error) {
      console.error('Failed to save project:', error);
      throw error;
    }
  },

  loadProject: async (projectId) => {
    try {
      const state = await ProjectStateService.load(projectId);
      if (await ProjectValidationService.validate(state, get().videoSHA)) {
        set({
          projectState: state,
          projectName: state.projectName,
          projectFileName: state.projectFileName,
          originalTranscript: state.originalTranscript,
          transcriptBlocks: state.decisions.blocks,
          sourceLanguage: state.sourceLanguage,
          breakAtPunctuation: state.decisions.settings.breakAtPunctuation,
          maxCharPerSection: state.decisions.settings.maxCharPerSection,
          breakOnSpeakerChange: state.decisions.settings.breakOnSpeakerChange,
          rejectLongSilences: state.decisions.settings.rejectLongSilences,
          longSilenceThreshold: state.decisions.settings.longSilenceThreshold,
          isDirty: false,
          lastSaved: new Date()
        });
        console.log('loadProject', get().transcriptBlocks);
      } else {
        throw new Error('Project validation failed');
      }
    } catch (error) {
      console.error('Failed to load project:', error);
      throw error;
    }
  }
});

export default useProjectStore;
