import { createRef } from 'react';

const useVideoStore = (set) => ({
  videoSHA: null,
  videoFile: null,
  videoFileName: null,
  isSeeking: false,
  flashingButton: null,
  videoProcessed: false,
  currentTime: 0,
  isPlaying: false,
  videoDuration: 0,
  videoPlayerRef: createRef(),
  setVideoSHA: (sha) => set({ videoSHA: sha }),
  setVideoFile: (file) => set({ videoFile: file }),
  setVideoFileName: (name) => set({ videoFileName: name }),
  setFlashingButton: (button) => {
    console.log('Setting flashing button:', button);
    set({ flashingButton: button });
  },
  setVideoProcessed: (processed) => set({ videoProcessed: processed }),
  setCurrentTime: (time) => set({ currentTime: time }),
  setIsPlaying: (playing) => set({ isPlaying: playing }),
  setIsSeeking: (seeking) => set({ isSeeking: seeking }),
  setVideoDuration: (duration) => set({ videoDuration: duration }),
});

export default useVideoStore;
