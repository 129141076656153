const useUploadStore = (set) => ({
  isUploading: false,
  uploadError: null,
  hasTranscriptData: false,
  transcriptData: null,
  sourceLanguage: null,
  setUploadError: (error) => set({ uploadError: error }),
  setIsUploading: (value) => set({ isUploading: value }),
  setTranscriptData: (data) => set({ 
    transcriptData: data,
    hasTranscriptData: !!data,
    sourceLanguage: data?.language || null
  }),
  clearTranscriptData: () => set({ 
    transcriptData: null,
    hasTranscriptData: false,
    sourceLanguage: null
  }),
  setSourceLanguage: (language) => set({ sourceLanguage: language }),
});

export default useUploadStore;