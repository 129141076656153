import React from 'react';
import authService from '../../services/AuthService';
import { Button } from '@nextui-org/react';
import useStore from '../../store';

export const Logout = ({ className, ...props }) => {
  const logout = useStore((state) => state.logout);

  const handleLogout = async () => {
    await authService.logout();
    logout();
  };

  return (
    <Button 
      onClick={handleLogout} 
      className={className}
      {...props}
    >
      Logout
    </Button>
  );
};

export default Logout;
