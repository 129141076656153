/**
 * Generates a JSON output for the given transcript blocks, excluding those that are rejected or beyond the rejection index.
 *
 * @param {Array} transcriptBlocks - An array of transcript block objects. Each block should have `start`, `end`, `content`, and `isRejected` properties.
 * @returns {string} - A formatted JSON string representing the filtered and transformed transcript blocks.
 */
const calculateDuration = (blocks) => {
  return blocks
    .filter(block => !block.isRejected)
    .reduce((total, block) => {
      return total + (block.end - block.start);
    }, 0);
};

const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0 || hours > 0) {
    parts.push(`${minutes}m`);
  }
  parts.push(`${remainingSeconds}s`);

  return parts.join(' ');
};

const generateJSONOutput = (transcriptBlocks, includeRejectedBlocks) => {
  const filteredBlocks = transcriptBlocks
    .filter((block) => includeRejectedBlocks || !block.isRejected)
    .map(block => ({
      start: block.start.toFixed(3),
      end: block.end.toFixed(3),
      text: block.content,
      isRejected: block.isRejected,
    }));

  const output = {
    SpookyCut: filteredBlocks,
    metadata: {
      duration: calculateDuration(transcriptBlocks).toFixed(3)
    }
  };
  
  return JSON.stringify(output, null, 2);
};

const JSONUpdateService = {
  generateJSONOutput,
  calculateDuration,
  formatDuration,
};

export default JSONUpdateService;