import React from 'react';
import { FindBar } from './index';

export const FindBarContainer = ({ isVisible, onSeek }) => {
  if (!isVisible) return null;

  return (
    <div className="bg-background/80 flex justify-end px-4 py-2">
      <div className="bg-background rounded-lg shadow-sm border border-default-200 p-2">
        <FindBar onSeek={onSeek} />
      </div>
    </div>
  );
}; 