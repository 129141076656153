import { useCallback } from 'react';
import useStore from '../store';

const formatSRTTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  const ms = Math.floor((seconds % 1) * 1000);
  
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')},${String(ms).padStart(3, '0')}`;
};

const splitIntoSentences = (words) => {
  const sentences = [];
  let currentSentence = [];
  
  // Define major sentence endings (a subset of punctuation endings)
  // Include only the endings that truly mark complete sentences
  const koreanSentenceEndings = [
    // Formal endings
    '습니다', '입니다', 'ㅂ니다',
    // Strong statement endings
    '이다', '하다', '다',
    // Clear sentence-final endings
    '네요', '는군요', '군요',
    // Question endings
    '까요', '나요', '가요',
    // Declarative endings
    '거든요', '잖아요'
  ].join('|');

  const sentenceEndPattern = new RegExp(
    `(?<!\\.)([.。!！?？]|(?<=\\s|.)(${koreanSentenceEndings}))(?=\\s|$)`,
    'u'
  );
  
  words.forEach(word => {
    // Skip silence entries
    if (word.type === 'silence') return;
    
    // Safety check for word property
    if (!word.word) {
      console.warn('Found word entry without word property:', word);
      return;
    }
    
    currentSentence.push(word);
    
    // Only split on major sentence endings
    if (sentenceEndPattern.test(word.word)) {
      sentences.push(currentSentence);
      currentSentence = [];
    }
  });
  
  if (currentSentence.length > 0) {
    sentences.push(currentSentence);
  }
  
  return sentences;
};

const splitByCharLimit = (words, charLimit) => {
  const lines = [];
  let currentLine = [];
  let currentLength = 0;
  
  words.forEach(word => {
    if (currentLength + word.word.length + 1 > charLimit && currentLine.length > 0) {
      lines.push(currentLine);
      currentLine = [];
      currentLength = 0;
    }
    currentLine.push(word);
    currentLength += word.word.length + 1;
  });
  
  if (currentLine.length > 0) {
    lines.push(currentLine);
  }
  
  return lines;
};

const getPatternForGranularity = (granularity, splitOnEllipses, splitOnLaughter) => {
  // Base Korean sentence endings (always included)
  const sentenceEndings = [
    '습니다', '입니다', 'ㅂ니다',
    '이다', '하다', '다',
    '네요', '는군요', '군요',
    '까요', '나요', '가요',
    '거든요', '잖아요'
  ].join('|');

  // Expanded punctuation endings
  const punctuationEndings = [
    // Basic polite endings
    '에요', '애요', '예요',
    '았어요', '었어요', '했어요',
    '해요', '죠', '요',
    // Formal endings
    '십니다', '겠습니다', 
    '하겠습니다',
    '드리겠습니다',
    '에서',
    // Conjunctive endings
    '하고', '되고', '시고',
    '니까',
    '갖고',
    '가진',
    // Connective endings
    '이고', '면서', '으며',
    '보면서',
    // Question/statement endings
    '인지',
    '은지',
    '는지'
  ].join('|');

  // Laughter patterns
  const laughterPatterns = [
    'ㅋ{2,}',     // Two or more ㅋ
    'ㅎ{2,}',     // Two or more ㅎ
    '하하+',      // Variations of 하하
    '호호+',      // Variations of 호호
    '히히+',      // Variations of 히히
    '흐+',        // Variations of 흐
    '헤+',        // Variations of 헤
    '후+',        // Variations of 후
    'ㄷㄷ',       // Shivering/surprise
  ].join('|');

  // Base pattern for sentence mode
  let pattern = `(?<!\\.)([.。!！?？]|(?<=\\s|.)(${sentenceEndings}))(?=\\s|$)`;
  
  if (granularity === 'punctuation') {
    pattern = 
      `(?<!\\.)([.。!！?？;；:：，,、؟؛।॥]|` +
      `(?<=\\s|.)(${sentenceEndings}|${punctuationEndings}))(?=\\s|$)`;
    
    // Add laughter patterns if enabled
    if (splitOnLaughter) {
      pattern += `|(${laughterPatterns})`;
    }
  }

  if (splitOnEllipses) {
    pattern += '|\\.{3}|…';
  }

  return new RegExp(pattern, 'u');
};

const splitByPattern = (words, pattern, maxGapSeconds = 3) => {
  const segments = [];
  let currentSegment = [];
  
  words.forEach((word, index) => {
    if (word.type === 'silence') return;
    
    if (!word.word) {
      console.warn('Found word entry without word property:', word);
      return;
    }

    // Check time gap with previous word
    if (currentSegment.length > 0) {
      const prevWord = currentSegment[currentSegment.length - 1];
      const timeGap = word.start - prevWord.end;
      
      if (timeGap > maxGapSeconds) {
        segments.push([...currentSegment]);
        currentSegment = [];
      }
    }
    
    currentSegment.push(word);
    
    if (pattern.test(word.word)) {
      segments.push([...currentSegment]);
      currentSegment = [];
    }
  });
  
  if (currentSegment.length > 0) {
    segments.push([...currentSegment]);
  }
  
  return segments;
};

export const useSRTExport = () => {
  const { transcriptBlocks } = useStore();
  
  const generateSRT = useCallback(({
    includeRejected = false,
    granularity = 'sentence',
    maxCharsPerLine = 0,
    splitOnEllipses = false,
    splitOnLaughter = true,
    maxGapSeconds = 3
  } = {}) => {
    let currentOffset = 0;
    let srtIndex = 1;
    let srtContent = '';
    
    const activeBlocks = transcriptBlocks
      .filter(block => includeRejected || !block.isRejected)
      .sort((a, b) => a.start - b.start);
    
    activeBlocks.forEach(block => {
      const blockDuration = block.end - block.start;
      const blockOffset = currentOffset;
      
      const nonSilenceWords = block.words
        .filter(word => !word.type || word.type !== 'silence');
      
      if (nonSilenceWords.length === 0) return;
      
      const adjustedWords = nonSilenceWords.map(word => ({
        ...word,
        adjustedStart: blockOffset + (word.start - block.start),
        adjustedEnd: blockOffset + (word.end - block.start)
      }));
      
      let segments;
      
      // Get the appropriate pattern based on granularity and settings
      const pattern = getPatternForGranularity(granularity, splitOnEllipses, splitOnLaughter);
      
      switch (granularity) {
        case 'word':
          segments = adjustedWords.map(word => [word]);
          break;
          
        case 'punctuation':
        case 'sentence':
          segments = splitByPattern(adjustedWords, pattern, maxGapSeconds);
          break;
          
        case 'block':
          segments = [adjustedWords];
          break;
          
        default:
          segments = splitByPattern(adjustedWords, pattern, maxGapSeconds);
      }
      
      // Apply character limit if specified
      if (maxCharsPerLine > 0) {
        segments = segments.flatMap(segment => splitByCharLimit(segment, maxCharsPerLine));
      }
      
      segments.forEach(segment => {
        if (segment.length === 0) return;
        
        const startTime = formatSRTTime(segment[0].adjustedStart);
        const endTime = formatSRTTime(segment[segment.length - 1].adjustedEnd);
        const text = segment.map(w => w.word).join(' ');
        
        srtContent += `${srtIndex}\n`;
        srtContent += `${startTime} --> ${endTime}\n`;
        srtContent += `${text}\n\n`;
        
        srtIndex++;
      });
      
      currentOffset += blockDuration;
    });
    
    return srtContent.trim();
  }, [transcriptBlocks]);
  
  return { generateSRT };
}; 