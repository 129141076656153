import React, { useState } from 'react';
import {
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Button,
  Select,
  SelectItem,
  Checkbox,
  Input,
  Spinner
} from "@nextui-org/react";

const defaultSettings = {
  granularity: 'punctuation',
  includeRejected: false,
  useMaxChars: false,
  maxCharsPerLine: 42,
  useTranslation: false,
  splitOnEllipses: true,
  splitOnLaughter: true,
  translationModel: 'google',
  targetLanguage: 'english',
  contextWindow: 3,
};

export const SRTExportModal = ({ isOpen, onClose, onExport }) => {
  const [settings, setSettings] = useState(defaultSettings);
  const [isTranslating, setIsTranslating] = useState(false);
  const [error, setError] = useState(null);

  // Show ellipses checkbox for both sentence and punctuation modes
  const showEllipsesOption = ['sentence', 'punctuation'].includes(settings.granularity);
  // Show parts of speech checkbox only for punctuation mode
  const showPartsOfSpeechOption = settings.granularity === 'punctuation';

  // When translation is toggled, update related settings
  const handleTranslationToggle = (isSelected) => {
    setSettings(prev => ({
      ...prev,
      useTranslation: isSelected,
      // If enabling translation, ensure default translation settings
      ...(isSelected && {
        translationModel: defaultSettings.translationModel,
        targetLanguage: defaultSettings.targetLanguage,
      })
    }));
  };

  const handleExport = async () => {
    setError(null);
    setIsTranslating(true);
    
    try {
      const exportSettings = {
        ...settings,
        maxCharsPerLine: settings.useMaxChars ? settings.maxCharsPerLine : 0
      };
      await onExport(exportSettings);
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Export SRT Settings</ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-4">
            <Select
              label="Subtitle Granularity"
              selectedKeys={[settings.granularity]}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                granularity: e.target.value
              }))}
            >
              <SelectItem key="word" value="word">Word by Word</SelectItem>
              <SelectItem key="punctuation" value="punctuation">By Punctuation</SelectItem>
              <SelectItem key="sentence" value="sentence">By Sentence</SelectItem>
              <SelectItem key="block" value="block">By Block</SelectItem>
            </Select>

            {settings.granularity === 'punctuation' && (
              <>
                <Checkbox
                  isSelected={settings.splitOnEllipses}
                  onValueChange={(isSelected) => setSettings(prev => ({
                    ...prev,
                    splitOnEllipses: isSelected
                  }))}
                >
                  Split on Ellipses (...)
                </Checkbox>
                <Checkbox
                  isSelected={settings.splitOnLaughter}
                  onValueChange={(isSelected) => setSettings(prev => ({
                    ...prev,
                    splitOnLaughter: isSelected
                  }))}
                >
                  Split on Laughter (ㅋㅋㅋ, ㅎㅎㅎ, etc.)
                </Checkbox>
              </>
            )}

            <Checkbox
              isSelected={settings.useMaxChars}
              onValueChange={(isSelected) => setSettings(prev => ({
                ...prev,
                useMaxChars: isSelected
              }))}
            >
              Limit Characters per Line
            </Checkbox>

            {settings.useMaxChars && (
              <Input
                type="number"
                label="Maximum Characters per Line"
                value={settings.maxCharsPerLine}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  maxCharsPerLine: parseInt(e.target.value) || 0
                }))}
              />
            )}

            <Checkbox
              isSelected={settings.includeRejected}
              onValueChange={(isSelected) => setSettings(prev => ({
                ...prev,
                includeRejected: isSelected
              }))}
            >
              Include Rejected Blocks
            </Checkbox>

            <Checkbox
              isSelected={settings.useTranslation}
              onValueChange={handleTranslationToggle}
            >
              Translate Subtitles
            </Checkbox>

            {settings.useTranslation && (
              <>
                <Select
                  label="Translation Model"
                  selectedKeys={[settings.translationModel]}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    translationModel: e.target.value
                  }))}
                >
                  <SelectItem key="google" value="google">Google Translate</SelectItem>
                  <SelectItem key="o1-preview" value="o1-preview">o1-preview (Latest)</SelectItem>
                  <SelectItem key="o1-mini" value="o1-mini">o1-mini (Latest)</SelectItem>
                  <SelectItem key="gpt4" value="gpt4">GPT-4</SelectItem>
                  <SelectItem key="gpt35" value="gpt35">GPT-3.5 Turbo</SelectItem>
                </Select>

                <Select
                  label="Target Language"
                  selectedKeys={[settings.targetLanguage]}
                  onChange={(e) => setSettings(prev => ({
                    ...prev,
                    targetLanguage: e.target.value
                  }))}
                >
                  <SelectItem key="english" value="english">English</SelectItem>
                  <SelectItem key="english-forced" value="english-forced">English (Forced Translation)</SelectItem>
                  <SelectItem key="spanish" value="spanish">Spanish</SelectItem>
                  <SelectItem key="french" value="french">French</SelectItem>
                  <SelectItem key="japanese" value="japanese">Japanese</SelectItem>
                  <SelectItem key="italian" value="italian">Italian</SelectItem>
                  <SelectItem key="russian" value="russian">Russian</SelectItem>
                  <SelectItem key="polish" value="polish">Polish</SelectItem>
                  <SelectItem key="portuguese" value="portuguese">Portuguese</SelectItem>
                </Select>
              </>
            )}
          </div>
        </ModalBody>
        
        {error && (
          <div className="text-red-500 text-sm mt-2">
            Error: {error}
          </div>
        )}
        
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            Cancel
          </Button>
          <Button 
            color="primary" 
            onPress={handleExport}
            isDisabled={isTranslating}
          >
            {isTranslating ? (
              <Spinner size="sm" color="white" />
            ) : (
              'Export'
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}; 