import { useState, useCallback } from 'react';
import { API_ENDPOINTS } from '../../config';
import { ProjectStateService, ProjectValidationService } from '../../services';
import useStore from '../../store';
import FileUploadService from '../../services/FileUploadService';
import { toast } from 'react-hot-toast';

export function useProjects() {
  const { projectState, isDirty, lastSaved, setProjectState, setIsDirty, saveProject, loadProject, projectFileName, projectName } = useStore();
  const videoSHA = useStore(state => state.videoSHA);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const {
    setTranscriptBlocks,
    setProjectName,
    setProjectFileName,
    setTranscriptData,
  } = useStore();

  const setVideoFile = useStore(state => state.setVideoFile);

  const listAllProjectsGroupedBySHA = useCallback(async () => {
    console.log('useProjects :: listAllProjectsGroupedBySHA from backend:', `/project/listAll`);
    try {
      const projects = await ProjectStateService.listAllProjectsGroupedBySHA();
      return projects;
    } catch (error) {
      console.error('Error listing all projects:', error);
      throw error;
    }
  }, []);

  const fetchFiles = useCallback(async (filterBySHA = null) => {
    try {
      console.log('fetchFiles: Starting with filterBySHA:', filterBySHA);
      const response = await listAllProjectsGroupedBySHA();
      const projectGroups = response?.data || [];

      if (filterBySHA) {
        const shaGroup = projectGroups.find(group => group.fileHash === filterBySHA);
        if (!shaGroup) {
          setFiles([]);
          return;
        }

        // Load project data for each file in the filtered group
        const projectsWithData = await Promise.all(shaGroup.projects.map(async project => {
          try {
            const projectData = await ProjectStateService.loadProject(
              shaGroup.fileHash,
              project.filename
            );
            return {
              ...project,
              videoSHA: shaGroup.fileHash,
              projectName: projectData.projectName || project.filename.replace('.json', '')
            };
          } catch (err) {
            console.warn(`Could not load project data for ${project.filename}:`, err);
            return {
              ...project,
              videoSHA: shaGroup.fileHash,
              projectName: project.filename.replace('.json', '')
            };
          }
        }));

        setFiles(projectsWithData);
        return;
      }

      // For all projects, load their data
      const allProjects = await Promise.all(projectGroups.map(async shaGroup => {
        if (!shaGroup || !Array.isArray(shaGroup.projects)) {
          return [];
        }

        const projectsWithData = await Promise.all(shaGroup.projects.map(async project => {
          try {
            const projectData = await ProjectStateService.loadProject(
              shaGroup.fileHash,
              project.filename
            );
            return {
              ...project,
              videoSHA: shaGroup.fileHash,
              projectName: projectData.projectName || project.filename.replace('.json', '')
            };
          } catch (err) {
            console.warn(`Could not load project data for ${project.filename}:`, err);
            return {
              ...project,
              videoSHA: shaGroup.fileHash,
              projectName: project.filename.replace('.json', '')
            };
          }
        }));

        return projectsWithData;
      }));

      // Flatten projects
      const flattenedProjects = allProjects.flat();

      // Helper function to get timestamp from filename
      const getTimestampFromFilename = (filename) => {
        const match = filename?.match(/(\d+)\.json$/);
        return match ? parseInt(match[1]) : 0;
      };

      // Sort by lastModified timestamp
      const sortedProjects = flattenedProjects.sort((a, b) => {
        // First try to get lastModified from metadata
        const aTime = a.metadata?.lastModified 
          ? new Date(a.metadata.lastModified).getTime() 
          : getTimestampFromFilename(a.filename);
        
        const bTime = b.metadata?.lastModified 
          ? new Date(b.metadata.lastModified).getTime() 
          : getTimestampFromFilename(b.filename);
        
        // Sort descending (newest first)
        return bTime - aTime;
      });

      setFiles(sortedProjects);
    } catch (err) {
      console.error('fetchFiles error:', err);
      setError(err.message);
      setFiles([]);
    }
  }, [listAllProjectsGroupedBySHA]);

  const save = async (saveAsNew = false) => {
    try {
      console.log('Starting save process...');
      const state = {
        videoSHA: projectState.videoSHA || videoSHA,
        // Only set new projectFileName if saving as new
        projectFileName: saveAsNew 
          ? `${Date.now()}.json` 
          : (projectState.projectFileName || projectFileName),
        projectName: projectState.projectName || projectName || new Date().getTime().toString(),
        originalTranscript: projectState.originalTranscript || '',
        decisions: {
          blocks: projectState.transcriptBlocks || [],
          settings: {
            breakAtPunctuation: projectState.breakAtPunctuation || false,
            maxCharPerSection: projectState.maxCharPerSection || 500,
            breakOnSpeakerChange: projectState.breakOnSpeakerChange || false,
            rejectLongSilences: projectState.rejectLongSilences || false,
            longSilenceThreshold: projectState.longSilenceThreshold || 1.0,
          }
        },
        metadata: {
          lastModified: new Date().toISOString(),
          version: '1.0.0'
        }
      };

      console.log('About to save state:', state);
      await ProjectStateService.save(state);
      await ProjectStateService.saveToBackend(
        projectState.videoSHA, 
        state.projectFileName, 
        state
      );
      console.log('Save completed');

      setIsDirty(false);
      setProjectState(state);
    } catch (error) {
      console.error('Error saving project:', error);
      throw error;
    }
  };

  const loadLocal = async () => {
    try {
      console.log('Starting load process...');
      const state = await ProjectStateService.load();
      console.log('Loaded state:', state);

    // Validate the loaded state
    const isValid = await ProjectValidationService.validate(state, videoSHA);
    if (!isValid) {
      throw new Error('Project state failed validation');
    }

      setProjectState(state);
      setIsDirty(false);
      loadProject(state);

      // the store transcriptBlocks should be hydrated now
      console.log('Load completed');
      return state;
    } catch (error) {
      console.error('Error loading project:', error);
      throw error;
    }
  }

  const loadRemoteProject = useCallback(async (project) => {
    try {
      console.log('Starting loadRemoteProject for:', project);
      
      // Fetch manifest to get original filename
      let originalFilename = null;
      try {
        const manifestResponse = await ProjectStateService.getManifest(project.videoSHA);
        console.log('Got manifest:', manifestResponse);
        originalFilename = manifestResponse?.manifest?.originalFilename;
      } catch (error) {
        console.warn('Failed to get manifest:', error);
      }

      // First try to get the transcript using FileUploadService
      let transcriptData = null;
      try {
        transcriptData = await FileUploadService.fetchTranscript('/api', project.videoSHA);
        console.log('Got transcript data:', transcriptData);
      } catch (error) {
        console.warn('Failed to get transcript:', error);
      }

      // Then load the project state
      const projectPath = project.filename || project.projectFileName + '.json';
      console.log('Loading project from path:', projectPath);
      
      try {
        const state = await ProjectStateService.loadProject(
          project.videoSHA, 
          projectPath
        );
        console.log('Loaded project state:', state);

        // Process the blocks through TranscriptProcessorService to ensure silences are properly formatted
        let processedBlocks = state.decisions?.blocks || [];
        if (transcriptData && processedBlocks.length > 0) {
          processedBlocks = processedBlocks.map(block => {
            if (!block.words) return block;
            
            return {
              ...block,
              words: block.words.map(word => {
                if (word.type === 'silence') {
                  const duration = word.end - word.start;
                  return {
                    ...word,
                    renderedContent: '[' + '.'.repeat(Math.ceil(duration)) + ']'
                  };
                }
                return word;
              })
            };
          });
        }

        // Update the store with complete state
        useStore.setState({
          transcriptBlocks: processedBlocks,
          projectName: state.projectName,
          projectFileName: state.projectFileName,
          videoSHA: project.videoSHA,
          originalTranscript: transcriptData?.text || '',
          transcriptData: transcriptData,
          hasTranscriptData: !!transcriptData,
          sourceLanguage: state.sourceLanguage || transcriptData?.language || null,
          videoFileName: originalFilename ?? state.videoFileName ?? 'Unknown File',
        });

        const updatedState = {
          ...state,
          decisions: {
            ...state.decisions,
            blocks: processedBlocks
          }
        };

        setProjectState(updatedState);
        setIsDirty(false);

        const videoConfig = {
          url: `/api/project/${project.videoSHA}/video`
        };

        setVideoFile(videoConfig);

        return updatedState;
      } catch (error) {
        console.error('Error loading project state:', error);
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
        }
        throw new Error(`Failed to load project: ${error.message}`);
      }
    } catch (error) {
      console.error('Error in loadRemoteProject:', error);
      throw error;
    }
  }, [setProjectState, setIsDirty]);


  const loadSelectedProject = useCallback(async (videoSHA, filename) => {
    try {
      const project = await loadRemoteProject(videoSHA, filename);

      if (!project) {
        console.warn('No project data found');
        return;
      }

      if (project.originalTranscript) {
        setTranscriptData(project.originalTranscript);
      }

      setTranscriptBlocks(project.decisions?.blocks || []);
      setProjectName(project.projectName || '');
      setProjectFileName(filename.replace('.json', ''));

    } catch (error) {
      console.error('Load project error:', error);
      setError('Failed to load project. Please try again.');
    }
  }, [loadRemoteProject, setTranscriptBlocks, setProjectName, setProjectFileName, setTranscriptData]);

  const listProjectsForSHA = useCallback(async (videoSHA) => {
    console.log('useProjects :: listProjectsForSHA for SHA:', videoSHA);
    try {
      const allProjects = await ProjectStateService.listAllProjectsGroupedBySHA();
      const shaGroup = allProjects.find(group => group.fileHash === videoSHA);
      
      if (!shaGroup) {
        console.log('No projects found for SHA:', videoSHA);
        return [];
      }

      return shaGroup.projects;
    } catch (error) {
      console.error('Error listing projects for SHA:', error);
      throw error;
    }
  }, []);

  const moveToTrash = useCallback(async (videoSHA, filename) => {
    try {
      const response = await ProjectStateService.moveProjectToTrash(videoSHA, filename);
      
      if (response.success) {
        // Show success toast
        toast.success('Project moved to trash');
        
        // Dispatch projectDeleted event
        console.log('Dispatching projectDeleted event');
        window.dispatchEvent(new CustomEvent('projectDeleted', {
          detail: { videoSHA, filename }
        }));
        
        // Refresh the files list
        await fetchFiles(videoSHA);
      }
    } catch (error) {
      console.error('Error moving project to trash:', error);
      
      // Handle specific error cases
      if (error.response?.status === 404) {
        toast.error('Project not found. It may have been already moved or deleted.');
      } else if (error.response?.status === 403) {
        toast.error('You do not have permission to move this project to trash.');
      } else {
        toast.error('Failed to move project to trash. Please try again.');
      }
      
      setError('Failed to move project to trash. Please try again.');
    }
  }, [fetchFiles]);

  return {
    projectState,
    isDirty,
    lastSaved,
    setProjectState,
    setIsDirty,
    saveProject: save,
    handleLoadProjectRemote: loadRemoteProject,
    handleLoadLocalProject: loadLocal,
    listAllProjectsGroupedBySHA,
    loadSelectedProject,
    fetchFiles,
    loadProject,
    files,
    error,
    listProjectsForSHA,
    moveToTrash,
  };
}

export default useProjects;