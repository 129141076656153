import React, { useState } from 'react';
import authService from '../../services/AuthService';
import { Button } from '@nextui-org/react';
import useStore from '../../store';

export const Signup = ({ onSuccess }) => {
  const setToken = useStore((state) => state.setToken);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [verificationBypass, setVerificationBypass] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      console.log('Attempting signup with:', { username });
      const response = await authService.signup(username, password, verificationBypass);
      console.log('Signup response:', response);
      
      if (response.token) {
        setToken(response.token);
        onSuccess?.();
      }
    } catch (error) {
      console.error('Signup error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSignup} className="flex flex-col border rounded-2xl gap-2 border-default p-2">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-2xl" role="alert">
          <p className="text-sm">{error}</p>
        </div>
      )}

      <input
        type="text"
        placeholder="Username"
        className="p-2 px-4 rounded-2xl"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={loading}
        required
        autoComplete="username"
      />
      <input
        type="password"
        placeholder="Password"
        className="p-2 px-4 rounded-2xl"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
        required
        autoComplete="new-password"
      />
      <input
        type="password"
        placeholder="Verification Code"
        className="p-2 px-4 rounded-2xl"
        value={verificationBypass}
        onChange={(e) => setVerificationBypass(e.target.value)}
        disabled={loading}
        autoComplete="off"
      />
      <Button 
        type="submit" 
        className="text-xl bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow"
        disabled={loading}
      >
        {loading ? 'Signing up...' : 'Sign Up'}
      </Button>
    </form>
  );
};

export default Signup;
