/**
 * Adjusts overlapping blocks by adding a tiny offset to the start time.
 * @param {Array} blocks - Array of transcript blocks.
 * @returns {Array} - Array of adjusted transcript blocks.
 */
export const preventOverlap = (blocks) => {
  return blocks.map((block, index) => {
    if (index === 0) return block;

    const prevBlock = blocks[index - 1];
    if (block.start === prevBlock.end) {
      // Only adjust if there's an exact overlap
      return {
        ...block,
        start: prevBlock.end + 0.001 // Add a tiny offset
      };
    }
    return block;
  });
};

// Path: src/utils/TranscriptUtils.js