import { useCallback, useRef } from 'react';
import useStore from '../../store';

/**
 * Custom hook to manage video seeking and playback state.
 *
 * @returns {Object} An object containing:
 * - `currentTime` {number}: The current playback time of the video.
 * - `isPlaying` {boolean}: The playback state of the video.
 * - `videoPlayerRef` {React.RefObject}: A reference to the video player element.
 * - `handleSeek` {function}: Function to seek the video to a specific time.
 * - `handleVideoPlayPause` {function}: Function to play or pause the video.
 * - `handleTimeUpdate` {function}: Function to update the current playback time.
 * - `handleFineSeek` {function}: Function to adjust the current playback time by a small amount.
 * - `handleStoppingOnRejectedBlock` {function}: Function to handle stopping the video when a rejected block is encountered.
 * - `handleVideoClick` {function}: Function to handle video click events.
 *
 */
export function useVideoSeeking() {
  const { currentTime, setCurrentTime } = useStore();
  const { isPlaying, setIsPlaying } = useStore();
  const videoPlayerRef = useStore(state => state.videoPlayerRef);

  const handleStoppingOnRejectedBlock = useCallback((currentBlock) => {
    if (videoPlayerRef.current) {
      if (currentBlock.isRejected) {
        videoPlayerRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, []);

  const handleSeek = useCallback((time) => {
    console.log('useVideoSeeking :: handleSeek START', {
      time,
      hasRef: !!videoPlayerRef?.current,
      currentTime
    });

    if (videoPlayerRef.current) {
      try {
        videoPlayerRef.current.seekTo(time);
        setCurrentTime(time);
        console.log('useVideoSeeking :: handleSeek COMPLETE', { newTime: time });
      } catch (error) {
        console.error('useVideoSeeking :: handleSeek ERROR', error);
      }
    } else {
      console.warn('useVideoSeeking :: handleSeek - No video player ref');
    }
  }, []);

  const handleVideoPlayPause = useCallback((newIsPlaying) => {
    setIsPlaying(newIsPlaying);
    if (videoPlayerRef.current) {
      if (newIsPlaying) {
        videoPlayerRef.current.play();
      } else {
        videoPlayerRef.current.pause();
      }
    }
  }, []);

  const handleTimeUpdate = useCallback((time) => {
    setCurrentTime(time);
  }, []);

  const handleFineSeek = useCallback((time) => {
    console.log('useVideoSeeking :: handleFineSeek START', {
      time,
      hasRef: !!videoPlayerRef?.current
    });

    if (videoPlayerRef.current) {
      try {
        // Ensure we have a valid, non-negative time
        const newTime = Math.max(0, Number(time.toFixed(3)));
        console.log('useVideoSeeking :: Seeking to:', newTime);
        videoPlayerRef.current.seekTo(newTime);
        setCurrentTime(newTime);
      } catch (error) {
        console.error('useVideoSeeking :: handleFineSeek ERROR', error);
      }
    } else {
      console.warn('useVideoSeeking :: No video player ref available');
    }
  }, [videoPlayerRef]);

  const handleVideoClick = useCallback(() => {
    if (videoPlayerRef.current) {
      setIsPlaying(!isPlaying);
      if (!isPlaying) {
        videoPlayerRef.current.play();
      } else {
        videoPlayerRef.current.pause();
      }
    }
  }, [isPlaying]);

  return {
    currentTime,
    isPlaying,
    videoPlayerRef,
    handleSeek,
    handleVideoPlayPause,
    handleTimeUpdate,
    handleFineSeek,
    handleStoppingOnRejectedBlock,
    handleVideoClick,
  };
}