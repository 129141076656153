// WordSelectionArea.jsx
import React, { useCallback, useMemo, useRef, useEffect, useLayoutEffect } from 'react';
import useStore from '../../store';
import './WordSelectionArea.css';
import { useBlockNavigation } from '../../hooks';

const Word = React.memo(({ item, index, isActive, isSelected, onMouseDown, onMouseEnter, onMouseUp, onClick, isPlaying }) => {
  const currentLanguage = useStore(state => state.currentLanguage);
  const localRef = useRef(null);
  const touchInfoRef = useRef({
    lastX: 0,
    lastY: 0,
    lastTime: 0,
  });
  
  // Get display text based on current language
  const displayText = item.type === 'silence' 
    ? item.renderedContent 
    : (currentLanguage !== 'english' && item.translations?.[currentLanguage])
      ? item.translations[currentLanguage]
      : item.word;

  // Setup touch event handlers
  useEffect(() => {
    const element = localRef.current;
    if (!element) return;

    const touchMoveHandler = (e) => {
      if (isPlaying) return;
      
      const touch = e.touches[0];
      const currentTime = performance.now();
      const deltaTime = currentTime - touchInfoRef.current.lastTime;
      
      // Calculate velocity (pixels per millisecond)
      const velocityY = Math.abs(touch.clientY - touchInfoRef.current.lastY) / deltaTime;
      const velocityX = Math.abs(touch.clientX - touchInfoRef.current.lastX) / deltaTime;
      
      // Update last position and time
      touchInfoRef.current = {
        lastX: touch.clientX,
        lastY: touch.clientY,
        lastTime: currentTime,
      };

      // If velocity is too high, let the scroll happen
      const VELOCITY_THRESHOLD = 0.5; // pixels per millisecond (adjust as needed)
      if (velocityY > VELOCITY_THRESHOLD || velocityX > VELOCITY_THRESHOLD) {
        return;
      }

      // Otherwise prevent scroll and handle selection
      e.preventDefault();
      
      const target = document.elementFromPoint(touch.clientX, touch.clientY);
      if (target?.classList.contains('word-box')) {
        const wordIndex = parseInt(target.dataset.wordIndex);
        if (!isNaN(wordIndex)) {
          onMouseEnter(wordIndex);
        }
      }
    };

    element.addEventListener('touchmove', touchMoveHandler, { passive: false });
    return () => element.removeEventListener('touchmove', touchMoveHandler);
  }, [isPlaying, onMouseEnter]);

  // Initialize touch info on touch start
  const handleTouchStart = (e) => {
    if (isPlaying) return;
    const touch = e.touches[0];
    touchInfoRef.current = {
      lastX: touch.clientX,
      lastY: touch.clientY,
      lastTime: performance.now(),
    };
    e.stopPropagation();
    onMouseDown(index);
  };

  const className = `word-box rounded rounded-sm px-1
    transition-all ease-in-out duration-100 cursor-pointer
    relative
    hover:after:content-[''] hover:after:absolute hover:after:left-0 hover:after:right-0
    hover:after:bottom-0 hover:after:h-[2px] hover:after:bg-yellow-300/60
    ${isSelected && isActive ? 'selected-active bg-yellow-100/70 before:absolute before:left-0 before:right-0 before:bottom-0 before:h-[2px] before:bg-sky-200' :
      isSelected ? 'selected bg-yellow-100/70' :
      isActive ? 'active bg-sky-200/70' : ''}
    ${isSelected || isActive ? 'text-black' :
      item.type === 'silence' ? 'text-gray-400' : ''}`;

  return (
    <span
      ref={localRef}
      data-word-index={index}
      className={className}
      onMouseDown={(e) => {
        if (isPlaying) return;
        e.stopPropagation();
        onMouseDown(index);
      }}
      onMouseEnter={(e) => {
        if (isPlaying) return;
        e.stopPropagation();
        onMouseEnter(index);
      }}
      onMouseUp={(e) => {
        if (isPlaying) return;
        e.stopPropagation();
        onMouseUp();
      }}
      onClick={(e) => {
        if (isPlaying) return;
        e.stopPropagation();
        onClick();
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={(e) => {
        if (isPlaying) return;
        e.stopPropagation();
        onMouseUp();
      }}
    >
      {displayText}
    </span>
  );
});

export const WordSelectionArea = ({
  words,
  currentTime,
  onSelectedWordsChange,
  onSeek,
  blockIndex,
  onBlockSelect,
}) => {
  const isTextInputFocused = useStore(state => state.isTextInputFocused);
  
  const { 
    isPlaying, 
    clearSelection, 
    isSelecting, 
    setIsSelecting,
    setSelectedWords,
    selectedWordsInBlock,
    setSelectionStart,
    selectionStart,
    transcriptBlocks 
  } = useStore();

  const { handleBlockNavigation } = useBlockNavigation(onSeek);

  const lastUpdateRef = useRef(0);
  const lastIndexRef = useRef(-1);
  const UPDATE_INTERVAL = 10; // ms between updates

  const activeWordIndex = useMemo(() => {
    const now = performance.now();
    if (now - lastUpdateRef.current < UPDATE_INTERVAL) {
      return lastIndexRef.current;
    }

    // Regular index calculation
    const blockStart = words[0].start;
    const blockEnd = words[words.length - 1].end;
    if (currentTime < blockStart || currentTime >= blockEnd) {
      lastIndexRef.current = -1;
      lastUpdateRef.current = now;
      return -1;
    }

    const newIndex = words.findIndex((word, index) => {
      if (index === words.length - 1) {
        return currentTime >= word.start && currentTime < blockEnd;
      }
      return currentTime >= word.start && currentTime < words[index + 1].start;
    });

    lastIndexRef.current = newIndex;
    lastUpdateRef.current = now;
    return newIndex;
  }, [words, currentTime]);

  const handleMouseDown = useCallback((index) => {
    if (isPlaying) return;
    onBlockSelect();
    setIsSelecting(true);
    setSelectionStart(index);
    clearSelection();
    onSelectedWordsChange([index]);
    onSeek(words[index].start);
  }, [onBlockSelect, onSelectedWordsChange, onSeek, words, clearSelection, setIsSelecting, setSelectionStart, isPlaying]);

  const handleMouseEnter = useCallback((index) => {
    if (isPlaying) return;
    if (isSelecting) {
      const start = Math.min(selectionStart, index);
      const end = Math.max(selectionStart, index);
      const selectedIndices = Array.from({ length: end - start + 1 }, (_, i) => start + i);
      onSelectedWordsChange(selectedIndices);
      setSelectedWords(blockIndex, selectedIndices);
      onSeek(words[index].start);
    }
  }, [isSelecting, onSelectedWordsChange, setSelectedWords, onSeek, words, blockIndex, selectionStart, isPlaying]);

  const handleMouseUp = useCallback(() => {
    if (isPlaying) return;
    setIsSelecting(false);
    const selectedWords = selectedWordsInBlock[blockIndex];
    if (selectedWords && selectedWords.length > 0) {
      const firstSelectedWordIndex = selectedWords[0];
      onSeek(words[firstSelectedWordIndex].start + 0.001);
    }
  }, [setIsSelecting, blockIndex, selectedWordsInBlock, onSeek, words, isPlaying]);

  const handleWordClick = useCallback((index) => {
    if (isPlaying || words[index].type === 'silence') return;
    onSelectedWordsChange([index]);
    onSeek(words[index].start);
  }, [words, onSelectedWordsChange, onSeek]);

  const wordPositionsRef = useRef(new Map());
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    
    const updateWordPositions = () => {
      const wordElements = containerRef.current.querySelectorAll('.word-box');
      const newPositions = new Map();
      
      wordElements.forEach((element, index) => {
        const rect = element.getBoundingClientRect();
        newPositions.set(index, {
          left: rect.left,
          top: rect.top,
          right: rect.right,
          bottom: rect.bottom,
          centerX: rect.left + rect.width / 2,
          centerY: rect.top + rect.height / 2,
          line: Math.round(rect.top / (rect.height + 4))
        });
      });
      
      wordPositionsRef.current = newPositions;
      // console.log('Word positions updated:', newPositions);
    };

    // Initial position calculation
    updateWordPositions();

    // Create ResizeObserver to watch for container size changes
    const resizeObserver = new ResizeObserver(() => {
      updateWordPositions();
    });

    // Start observing the container
    resizeObserver.observe(containerRef.current);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [words]);

  const lastKeyPressRef = useRef({
    time: 0,
    key: null,
    handled: false
  });
  const KEY_PRESS_INTERVAL = 150;

  const handleKeyDown = useCallback((e) => {
    // Only handle vertical navigation and shift+navigation here
    // Let useKeyPress handle simple left/right navigation
    const isVertical = ['ArrowUp', 'ArrowDown', 'w', 's'].includes(e.key.toLowerCase());
    const isHorizontal = ['ArrowLeft', 'ArrowRight', 'a', 'd'].includes(e.key.toLowerCase());
    
    // If it's horizontal navigation without shift, let useKeyPress handle it
    if (isHorizontal && !e.shiftKey) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (!lastKeyPressRef.current.handled) {
      console.log('WordSelectionArea handling:', {
        key: e.key,
        isShift: e.shiftKey,
        isVertical,
        isHorizontal,
        activeWordIndex,
        selectionStart,
        currentSelection: selectedWordsInBlock[blockIndex]
      });
    }

    if (activeWordIndex === -1) return;

    let targetWord = null;

    if (isVertical) {
      const currentPos = wordPositionsRef.current.get(activeWordIndex);
      if (!currentPos) return;

      const direction = (['ArrowUp', 'w'].includes(e.key.toLowerCase())) ? -1 : 1;
      
      // Find closest word in next/previous line
      let minDistance = Infinity;
      wordPositionsRef.current.forEach((pos, index) => {
        if ((direction === -1 && pos.line === currentPos.line - 1) ||
            (direction === 1 && pos.line === currentPos.line + 1)) {
          const horizontalDist = Math.abs(pos.centerX - currentPos.centerX);
          if (horizontalDist < minDistance) {
            minDistance = horizontalDist;
            targetWord = index;
          }
        }
      });

      // If no target word found and we're at a block boundary, handle block navigation
      if (targetWord === null) {
        const lines = [...new Set([...wordPositionsRef.current.values()].map(pos => pos.line))].sort((a, b) => a - b);
        const isFirstLine = currentPos.line === Math.min(...lines);
        const isLastLine = currentPos.line === Math.max(...lines);
        
        if ((direction === -1 && isFirstLine) || (direction === 1 && isLastLine)) {
          handleBlockNavigation(direction, { selectLastWord: direction === -1 });
          return;
        }
      }
    } else if (isHorizontal) {
      // Handle horizontal movement
      const direction = ['ArrowLeft', 'a'].includes(e.key.toLowerCase()) ? -1 : 1;
      const nextIndex = activeWordIndex + direction;
      
      // Check if we need to move to another block
      if (nextIndex < 0 || nextIndex >= words.length) {
        // When moving left (direction = -1), set a flag to select the last word of the previous block
        handleBlockNavigation(direction, { selectLastWord: direction === -1 });
        return;
      }
      
      targetWord = nextIndex;
    }

    if (targetWord !== null) {
      if (e.shiftKey) {
        // Set anchor point if this is the start of selection
        if (selectionStart === null) {
          console.log('Setting selection anchor:', {
            index: activeWordIndex,
            reason: 'First shift press',
            movement: isVertical ? 'vertical' : 'horizontal'
          });
          setSelectionStart(activeWordIndex);
        }

        const anchor = selectionStart ?? activeWordIndex;
        const start = Math.min(anchor, targetWord);
        const end = Math.max(anchor, targetWord);
        const selectedIndices = Array.from({ length: end - start + 1 }, (_, i) => start + i);

        console.log('Updating selection:', {
          anchor,
          start,
          end,
          selectedIndices,
          previousSelection: selectedWordsInBlock[blockIndex],
          movement: {
            type: isVertical ? 'vertical' : 'horizontal',
            direction: e.key
          }
        });

        onSelectedWordsChange(selectedIndices);
        setSelectedWords(blockIndex, selectedIndices);
      } else {
        console.log('Clearing selection:', {
          reason: 'Non-shift navigation',
          previousAnchor: selectionStart,
          newActiveWord: targetWord
        });
        onSelectedWordsChange([targetWord]);
        clearSelection();
        setSelectionStart(null);
      }
      onSeek(words[targetWord].start);
    }
  }, [
    activeWordIndex,
    words,
    onSelectedWordsChange,
    onSeek,
    isPlaying,
    selectedWordsInBlock,
    blockIndex,
    setSelectedWords,
    clearSelection,
    setSelectionStart,
    selectionStart,
    isTextInputFocused
  ]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Early returns
      if (isTextInputFocused || isPlaying) return;
      if ((e.metaKey || e.ctrlKey)) return;

      // Only handle vertical navigation and shift+navigation
      const isVertical = ['ArrowUp', 'ArrowDown', 'w', 's'].includes(e.key.toLowerCase());
      const isHorizontal = ['ArrowLeft', 'ArrowRight', 'a', 'd'].includes(e.key.toLowerCase());
      
      if (!isVertical && !(isHorizontal && e.shiftKey)) return;

      const now = performance.now();
      const lastPress = lastKeyPressRef.current;

      if (e.key !== lastPress.key || (now - lastPress.time) >= KEY_PRESS_INTERVAL) {
        lastKeyPressRef.current = {
          time: now,
          key: e.key,
          handled: false
        };
        
        handleKeyDown(e);
        lastKeyPressRef.current.handled = true;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    document.addEventListener('keydown', handleKeyPress, true);
    return () => document.removeEventListener('keydown', handleKeyPress, true);
  }, [handleKeyDown, isTextInputFocused, isPlaying]);

  // Add cleanup for keyup
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (lastKeyPressRef.current.key === e.key) {
        lastKeyPressRef.current = {
          time: 0,
          key: null,
          handled: false
        };
      }
    };

    document.addEventListener('keyup', handleKeyUp, true);
    return () => document.removeEventListener('keyup', handleKeyUp, true);
  }, []);

  return (
    <div 
      ref={containerRef}
      className="word-selection-area p-4 pr-12 flex flex-wrap"
    >
      {words.map((item, index) => (
        <Word
          key={item.type === 'silence' ? `silence-${index}` : `word-${index}`}
          item={item}
          index={index}
          isActive={index === activeWordIndex}
          isSelected={selectedWordsInBlock[blockIndex]?.includes(index)}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseUp={handleMouseUp}
          onClick={() => handleWordClick(index)}
        />
      ))}
    </div>
  );
};
