import React from 'react';
import { Button, Card, CardBody } from '@nextui-org/react';
import { SRTExportModal } from '../SRTExportModal';
import { ExportPremiereModal } from '../ExportPremiereModal';
import { useSRTExport } from '../../hooks/useSRTExport';
import { TranslateSRTService } from '../../services/TranslateSRTService';
import useStore from '../../store';

export const OutputSection = () => {
  const [isSRTModalOpen, setIsSRTModalOpen] = React.useState(false);
  const [isPremiereModalOpen, setIsPremiereModalOpen] = React.useState(false);
  const [isTranslating, setIsTranslating] = React.useState(false);
  const { generateSRT } = useSRTExport();

  const handleExportSRT = async (settings) => {
    const { sourceLanguage } = useStore.getState();
    
    try {
      const srtContent = generateSRT(settings);
      
      if (settings.useTranslation && settings.targetLanguage) {
        setIsTranslating(true);
        try {
          const translatedContent = await TranslateSRTService.translateSRT(
            srtContent,
            settings.targetLanguage,
            sourceLanguage,
            settings.translationModel,
            settings.granularity === 'punctuation'
          );
          
          const blob = new Blob([translatedContent], { type: 'text/plain' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `subtitles_${settings.targetLanguage}.srt`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Translation failed:', error);
        }
      } else {
        const blob = new Blob([srtContent], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'subtitles.srt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Export error:', error);
    } finally {
      setIsTranslating(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <section className="flex flex-col gap-4">
          <Button
            onClick={() => setIsSRTModalOpen(true)}
            className="bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow"
          >
            Export SRT
          </Button>

          <Button
            onClick={() => setIsPremiereModalOpen(true)}
            className="bg-gradient-to-tr from-pink-500 to-yellow-500 text-white shadow-lg hover:shadow-xl transition-shadow"
          >
            Export for Premiere
          </Button>

          <SRTExportModal
            isOpen={isSRTModalOpen}
            onClose={() => setIsSRTModalOpen(false)}
            onExport={handleExportSRT}
            isTranslating={isTranslating}
          />

          <ExportPremiereModal
            isOpen={isPremiereModalOpen}
            onClose={() => setIsPremiereModalOpen(false)}
          />
        </section>
      </CardBody>
    </Card>
  );
};
