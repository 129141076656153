import { useState, useEffect } from 'react';
import { Button, ButtonGroup, Tooltip, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, RadioGroup, Radio, Input } from '@nextui-org/react';
import useStore from '../../store';
import { ProjectStateService } from '../../services';
import { KeyboardShortcutsModal } from '../KeyboardShortcuts/KeyboardShortcutsModal';
import { FiFilter as IsolateIcon, FiScissors as ScissorsIcon, FiMinusCircle as RejectIcon } from "react-icons/fi";
import { BiSearch as SearchIcon } from "react-icons/bi";
import { BiCopy } from 'react-icons/bi';
import { TbGitCherryPick } from "react-icons/tb";
import { PiCursorTextLight } from "react-icons/pi";
import { VscBug } from "react-icons/vsc";
import { BsKeyboard } from "react-icons/bs";
import './ActionBar.css';

const SplitButton = ({ selectedBlock }) => {
  const { splitBlock } = useStore();
  const isDisabled = !selectedBlock;

  return (
    <Tooltip content="Split at selection or Current Word">
      <Button isIconOnly size="sm"
        className={`split-button p-[.38rem] flex flex-col min-w-0 button
          bg-yellow-100/70
          font-bold border-none cursor-pointer transition-all duration-300 ease-in-out
          ${selectedBlock?.isRejected ? 'rejected hover:bg-red-500/60' : 'hover:bg-green-500/60'}`}
        onClick={splitBlock}
        disabled={isDisabled}
        aria-label="Split Block"
      >
        <ScissorsIcon size="24" />
      </Button>
    </Tooltip>
  );
};

const IsolateButton = ({ selectedBlock }) => {
  const {
    isolateSelectedWords,
    selectedWordsInBlock,
    currentBlockIndex,
    getSelectedWordsInCurrentBlock
  } = useStore();

  const selectedWords = getSelectedWordsInCurrentBlock();
  const isDisabled = !selectedBlock || !selectedWords?.length;

  return (
    <Tooltip content="Isolate selected words, reject the rest">
      <Button isIconOnly size="sm"
        className={`isolate-button p-[.38rem] flex flex-col min-w-0 button
          bg-blue-100/70
          font-bold border-none cursor-pointer transition-all duration-300 ease-in-out
          ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}
          ${selectedBlock?.isRejected ? 'rejected hover:bg-red-500/60' : 'hover:bg-blue-500/60'}`}
        onClick={isolateSelectedWords}
        isDisabled={isDisabled}
        aria-label="Isolate Selected Words"
      >
        <TbGitCherryPick size="24" />
      </Button>
    </Tooltip>
  );
};

const RejectButton = ({ selectedBlock }) => {
  const {
    handleRejectBlock,
    selectedWordsInBlock,
    selectedBlockIndex,
    isPlaying,
    clearSelection,
  } = useStore();

  const handleReject = () => {
    if (isPlaying) return;
    if (selectedBlockIndex === null) return;

    const selectedWords = selectedWordsInBlock[selectedBlockIndex];
    
    if (selectedWords?.length > 0) {
      useStore.getState().splitBlockAndRejectSelectedWords();
      clearSelection();
    } else {
      handleRejectBlock(selectedBlockIndex);
    }
  };

  const isDisabled = !selectedBlock;

  return (
    <Tooltip content="Reject selected words or block (R)">
      <Button isIconOnly size="sm"
        className={`reject-button p-[.38rem] flex flex-col min-w-0 button
          bg-red-100/70
          font-bold border-none cursor-pointer transition-all duration-300 ease-in-out
          ${selectedBlock?.isRejected ? 'rejected hover:bg-red-500/60' : 'hover:bg-red-500/60'}`}
        onPress={handleReject}
        isDisabled={isDisabled}
        aria-label="Reject Block or Selection"
      >
        <RejectIcon size="24" />
      </Button>
    </Tooltip>
  );
};

export const ActionBar = ({ onFineSeek }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isKeyboardShortcutsVisible, setIsKeyboardShortcutsVisible] = useState(false);
  const [saveType, setSaveType] = useState("update");
  const [projectNameInput, setProjectNameInput] = useState('');

  // Get everything from the main store
  const {
    saveProject,
    revertToLastSave,
    transcriptBlocks,
    isDirty,
    canUndo,
    canRedo,
    undo,
    redo,
    selectedBlockIndex,
    currentTime,
    currentBlockIndex,
    getCurrentWordIndex,
    toggleFindBar,
    isFindBarVisible,
    addBlock,
    projectName: currentProjectName,
    projectFileName: currentProjectFileName,
    setProjectName,
    setProjectFileName,
    setIsTextInputFocused,
    isSaveModalOpen,
    setIsSaveModalOpen,
    handleRejectBlock,
    selectedWordsInBlock,
    isPlaying,
    clearSelection,
  } = useStore();

  useEffect(() => {
    if (isSaveModalOpen) {
      setProjectNameInput(currentProjectName || '');
      setSaveType('update');
    }
  }, [isSaveModalOpen, currentProjectName]);

  const handleManualSave = async () => {
    try {
      const isNewProject = saveType === "new";
      
      if (isNewProject && !projectNameInput.trim()) {
        return;
      }

      console.log('Initiating save with:', {
        isNewProject,
        projectNameInput,
        currentProjectFileName
      });

      // Update project name in the store
      setProjectName(projectNameInput.trim());
      
      // For new projects, we don't set the filename - it will be generated
      if (!isNewProject) {
        setProjectFileName(currentProjectFileName);
      }
      
      await saveProject(isNewProject, projectNameInput.trim());
      
      // Dispatch event after successful save
      console.log('Save completed, dispatching projectSaved event');
      window.dispatchEvent(new CustomEvent('projectSaved'));
      
      setIsSaveModalOpen(false);
      setSaveType('update');
      setProjectNameInput('');
    } catch (error) {
      console.error('Manual save failed:', error);
    }
  };

  const selectedBlock = selectedBlockIndex !== null ? transcriptBlocks[selectedBlockIndex] : null;

  return (
    <div className="action-bar-container">
      <div className="action-buttons-left">
        <Tooltip content="Keyboard Shortcuts">
          <Button
            isIconOnly
            size="sm"
            onClick={() => setIsKeyboardShortcutsVisible(true)}
            className="transition-colors hover:!opacity-100 hover:brightness-110"
          >
            <BsKeyboard size={20} />
          </Button>
        </Tooltip>
        <Tooltip content="Debug State">
          <Button
            isIconOnly
            size="sm"
            onClick={() => setIsVisible(!isVisible)}
            className="transition-colors hover:!opacity-100 hover:brightness-110"
          >
            <VscBug size={20} />
          </Button>
        </Tooltip>
        <Button
          size="sm"
          isDisabled={!isDirty}
          onPress={() => setIsSaveModalOpen(true)}
          className={`transition-colors hover:!opacity-100 !opacity-100 ${
            isDirty ? 
            'bg-gradient-to-tr from-purple-500 to-blue-500 text-white shadow-lg hover:shadow-xl transition-shadow animate-[pulse_2s_ease-in-out_infinite]' : 
            'bg-gradient-to-tr from-purple-500/30 to-blue-500/30 text-default-700'
          }`}
        >
          Save Project
        </Button>
        <div className="flex gap-1">
          <Button
            size="sm"
            isDisabled={!canUndo()}
            onClick={undo}
            title="Ctrl+Z"
            className={`transition-colors hover:!opacity-100 !opacity-100 ${
              canUndo() ? 
              'bg-gradient-to-tr from-pink-500/75 to-yellow-500/75 text-white shadow-lg hover:shadow-xl transition-shadow' : 
              'bg-gradient-to-tr from-pink-500/20 to-yellow-500/20 text-default-700'
            }`}
          >
            Undo
          </Button>
          <Button
            size="sm"
            isDisabled={!canRedo()}
            onClick={redo}
            title="Ctrl+Y or Ctrl+Shift+Z"
            className={`transition-colors hover:!opacity-100 !opacity-100 ${
              canRedo() ? 
              'bg-gradient-to-tr from-orange-500/75 to-green-500/75 text-white shadow-lg hover:shadow-xl transition-shadow' : 
              'bg-gradient-to-tr from-orange-500/20 to-green-500/20 text-default-700'
            }`}
          >
            Redo
          </Button>
        </div>
      </div>

      <div className="action-buttons-right">
        <Tooltip content="Toggle Find Bar">
          <Button
            isIconOnly
            size="sm"
            className={`p-[.38rem] flex flex-col min-w-0 button
              font-bold border-none cursor-pointer transition-all duration-300 ease-in-out hover:bg-indigo-500/60
              ${isFindBarVisible ? 'bg-indigo-200' : 'bg-indigo-100/70'}`}
            onClick={toggleFindBar}
            aria-label="Toggle Find Bar"
          >
            <SearchIcon size="24" />
            <span className="sr-only">{isFindBarVisible ? 'visible' : 'hidden'}</span>
          </Button>
        </Tooltip>
        <SplitButton selectedBlock={selectedBlock} />
        <RejectButton selectedBlock={selectedBlock} />
        <IsolateButton selectedBlock={selectedBlock} />
        <Tooltip content="Toggle Word Editor">
          <Button
            isIconOnly
            size="sm"
            className="p-[.38rem] flex flex-col min-w-0 button
              bg-purple-100/70
              font-bold border-none cursor-pointer transition-all duration-300 ease-in-out hover:bg-purple-500/60"
            onClick={() => useStore.getState().toggleContextualToolbar()}
            aria-label="Toggle Word Editor"
          >
            <PiCursorTextLight size="24" />
          </Button>
        </Tooltip>
        <Tooltip content="Duplicate Selected Block">
          <Button
            isIconOnly
            size="sm"
            className="action-button"
            onClick={() => {
              const selectedBlock = transcriptBlocks[selectedBlockIndex];
              if (selectedBlock) {
                const duplicatedBlock = {
                  ...selectedBlock,
                  id: crypto.randomUUID(),
                };
                addBlock(duplicatedBlock, selectedBlockIndex + 1);
              }
            }}
            disabled={selectedBlockIndex === null}
            aria-label="Duplicate Block"
          >
            <BiCopy />
          </Button>
        </Tooltip>
      </div>

      <KeyboardShortcutsModal
        isOpen={isKeyboardShortcutsVisible}
        onClose={() => setIsKeyboardShortcutsVisible(false)}
      />

      <Modal 
        isOpen={isSaveModalOpen} 
        onClose={() => {
          setIsSaveModalOpen(false);
          setSaveType('update');
          setProjectNameInput('');
        }}
        placement="center"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Save Project
              </ModalHeader>
              <ModalBody>
                <RadioGroup
                  value={saveType}
                  onValueChange={setSaveType}
                  className="mb-4"
                >
                  <Radio value="update">
                    Update Current Project
                    <span className="text-xs text-default-500 block">
                      Cut ID: {currentProjectFileName?.replace('.json', '')}
                    </span>
                  </Radio>
                  <Radio value="new">
                    Create New Project
                    <span className="text-xs text-default-500 block">
                      Will generate a new Cut ID
                    </span>
                  </Radio>
                </RadioGroup>

                <div className="space-y-2">
                  <Input
                    label={saveType === "new" ? "New Project Name" : "Project Name"}
                    placeholder="Enter project name"
                    value={projectNameInput}
                    onChange={(e) => {
                      setProjectNameInput(e.target.value);
                      if (saveType !== "new") {
                        // Direct store update for existing projects
                        setProjectName(e.target.value);
                      }
                    }}
                    onFocus={() => setIsTextInputFocused(true)}
                    onBlur={() => setIsTextInputFocused(false)}
                    isRequired={saveType === "new"}
                    description={saveType === "new" ? 
                      "Required for new projects" : 
                      "Optionally update the current project's name"
                    }
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button 
                  variant="light" 
                  onPress={() => {
                    onClose();
                    setSaveType('update');
                    setProjectNameInput('');
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  color="primary" 
                  onPress={() => {
                    handleManualSave();
                    onClose();
                  }}
                  isDisabled={saveType === "new" && !projectNameInput.trim()}
                >
                  {saveType === "new" ? 'Create New' : 'Update'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {isVisible && (
        <div className="mt-2 p-4 bg-background/80 rounded-lg border border-default-200 max-h-[80vh] overflow-auto">
          <div className="mb-2 space-y-2">
            <div>
              <strong>isDirty:</strong> {isDirty ? 'true' : 'false'}
            </div>
            <div>
              <strong>Blocks Count:</strong> {transcriptBlocks?.length || 0}
            </div>
            <div>
              <strong>History:</strong> {canUndo() ? 'U' : '·'}{canRedo() ? 'R' : '·'}
            </div>

            <div className="flex items-center gap-4 border-t border-default-200 pt-2 mt-2">
              <div>
                <strong>Current Time:</strong> <span className="font-mono">{currentTime?.toFixed(3)}</span>
              </div>
              <div>
                <strong>Block:</strong> <span className="font-mono">{currentBlockIndex}</span>
              </div>
              <div>
                <strong>Word:</strong> <span className="font-mono">{getCurrentWordIndex()}</span>
              </div>
              <ButtonGroup size="sm">
                <Button onClick={() => onFineSeek(-1)} title="Seek 1ms backward">{'<'}</Button>
                <Button onClick={() => onFineSeek(1)} title="Seek 1ms forward">{'>'}</Button>
              </ButtonGroup>
            </div>
          </div>
          <pre className="text-xs whitespace-pre-wrap break-words">
            {JSON.stringify(ProjectStateService.getCurrentState(), null, 2) || 'No saved state'}
          </pre>
        </div>
      )}
    </div>
  );
};