import axios from '../../utils/axios';
import useStore from '../../store';

// Language code mapping
const languageMap = {
  english: 'en',
  spanish: 'es',
  french: 'fr',
  japanese: 'ja',
  italian: 'it',
  russian: 'ru',
  polish: 'pl',
  korean: 'ko',
};

class TranslationService {
  static async requestTranslation(fileHash, targetLanguage, sourceLanguage) {
    try {
      // Convert language names to codes if needed
      const targetLangCode = languageMap[targetLanguage.toLowerCase()] || targetLanguage;
      const sourceLangCode = languageMap[sourceLanguage.toLowerCase()] || sourceLanguage;

      console.log('[TRANSLATE] Preparing request:', {
        fileHash,
        targetLanguage,
        targetLangCode,
        sourceLanguage,
        sourceLangCode
      });

      const response = await axios.post('/project/translate', {
        videoSHA: fileHash,
        targetLanguage: targetLangCode,
        sourceLanguage: sourceLangCode,
        force: false
      });

      // Immediately update available languages when we get new translations
      if (response.data.translations) {
        useStore.getState().updateAvailableTranslations(response.data.translations);
      }

      return response.data;
    } catch (error) {
      console.error('[TRANSLATE] Error:', error);
      throw error;
    }
  }
}

export default TranslationService; 